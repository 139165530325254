import { PrimaryButton, Spinner, SpinnerSize, Stack, Text, TextField } from '@fluentui/react';
import { FC, useContext, useEffect, useState } from 'react';
import { SettingsServiceContext } from '../../../Services/API/SettingsService';
import ISettings from '../../../Models/API/ISettings';
import { DialogServiceContext } from '../../../Services/Dialogs/DialogService';
import moment from 'moment';
import { trackPageView } from '../../../Services/AppInsights';

const PromptSettings: FC = () => {
  const settingsService = useContext(SettingsServiceContext);
  const dialogService = useContext(DialogServiceContext);

  const [settings, setSettings] = useState<ISettings | null>(null);
  const [saving, setSaving] = useState<boolean>(false);
  const [isMounted, setIsMounted] = useState<boolean>(true);

  // Track page view
  useEffect(() => {
    trackPageView();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const execute = async () => {
      var reply = await settingsService!.Get();
      if (isMounted) setSettings(reply);
    };
    execute();
    return () => {
      setIsMounted(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const saveSettings = async () => {
    setSaving(true);
    await settingsService?.Save(settings!);
    dialogService?.showSuccessDialog('Settings saved', 'All settings have been saved.');
    setSaving(false);
  };

  return (
    (settings && (
      <>
        <Stack tokens={{ childrenGap: 20 }}>
          <Stack.Item style={{ maxWidth: 600, width: 600 }}>
            <TextField
              value={settings.first_prompt}
              onChange={event =>
                setSettings({
                  ...settings,
                  first_prompt: event.currentTarget.value,
                })
              }
              label='Search Keywords Prompt'
              multiline
              rows={15}
            />
            <Text variant={'xSmall'} block>
              The prompt sent to GPT to get the search keywords for documents.
              <br />
              <strong>
                {'{'}conversation_history{'}'}
              </strong>{' '}
              - The last 10 messages in the conversation
              <br />
              <strong>
                {'{'}name{'}'}
              </strong>{' '}
              - The user's full name
              <br />
              <strong>
                {'{'}office_location{'}'}
              </strong>{' '}
              - The user's office location
              <br />
              <strong>
                {'{'}job_title{'}'}
              </strong>{' '}
              - The user's job title
              <br />
              <strong>
                {'{'}country{'}'}
              </strong>{' '}
              - The user's country
            </Text>
          </Stack.Item>
          <Stack.Item style={{ maxWidth: 600, width: 600 }}>
            <TextField
              value={settings.no_model_sources_prompt}
              onChange={event =>
                setSettings({
                  ...settings,
                  no_model_sources_prompt: event.currentTarget.value,
                })
              }
              label='No Model Sources Prompt'
              multiline
              rows={8}
            />
            <Text variant={'xSmall'} block>
              The prompt part sent to the LLM if the chat application does not allow external
              sources.
              <br />
              <strong>
                {'{'}sources{'}'}
              </strong>{' '}
              - The content of the indexed documents
              <br />
              <strong>
                {'{'}no_answer_message{'}'}
              </strong>{' '}
              - The "no answer message" defined in the chat application.
            </Text>
          </Stack.Item>
          <Stack.Item style={{ maxWidth: 600, width: 600 }}>
            <TextField
              value={settings.allow_model_sources_prompt}
              onChange={event =>
                setSettings({
                  ...settings,
                  allow_model_sources_prompt: event.currentTarget.value,
                })
              }
              label='Allow Model Sources Prompt'
              multiline
              rows={8}
            />
            <Text variant={'xSmall'} block>
              The prompt part sent to the LLM if the chat application allows external sources.
              <br />
              <strong>
                {'{'}sources{'}'}
              </strong>{' '}
              - The content of the indexed documents
              <br />
              <strong>
                {'{'}no_answer_message{'}'}
              </strong>{' '}
              - The "no answer message" defined in the chat application.
            </Text>
          </Stack.Item>
          <Stack.Item style={{ maxWidth: 600, width: 600 }}>
            <TextField
              value={settings.user_information_prompt}
              onChange={event =>
                setSettings({
                  ...settings,
                  user_information_prompt: event.currentTarget.value,
                })
              }
              label='User Information Prompt'
              multiline
              rows={5}
            />
            <Text variant={'xSmall'} block>
              The prompt part sent to the LLM with user information.
              <br />
              <strong>
                {'{'}name{'}'}
              </strong>{' '}
              - The user's full name
              <br />
              <strong>
                {'{'}office_location{'}'}
              </strong>{' '}
              - The user's office location
              <br />
              <strong>
                {'{'}job_title{'}'}
              </strong>{' '}
              - The user's job title
              <br />
              <strong>
                {'{'}country{'}'}
              </strong>{' '}
              - The user's country
            </Text>
          </Stack.Item>
          <Stack.Item style={{ maxWidth: 600, width: 600 }}>
            <TextField
              value={settings.function_call_prompt}
              onChange={event =>
                setSettings({
                  ...settings,
                  function_call_prompt: event.currentTarget.value,
                })
              }
              label='Function Prompt'
              multiline
              rows={5}
            />
            <Text variant={'xSmall'} block>
              The function prompt part sent to the LLM when functions are available.
            </Text>
          </Stack.Item>
          <Stack.Item style={{ maxWidth: 600, width: 600 }}>
            <TextField
              value={settings.generic_prompt}
              onChange={event =>
                setSettings({
                  ...settings,
                  generic_prompt: event.currentTarget.value,
                })
              }
              label='Generic Prompt'
              multiline
              rows={5}
            />
            <Text variant={'xSmall'} block>
              The last prompt part sent to the LLM.
              <br />
              <strong>
                {'{'}date{'}'}
              </strong>{' '}
              - The current date ({moment().format('DD/MM/yyyy')})
              <br />
              <strong>
                {'{'}day{'}'}
              </strong>{' '}
              - The current day of the month ({moment().date()})
              <br />
              <strong>
                {'{'}weekday{'}'}
              </strong>{' '}
              - The current week day ({moment.weekdays(moment().weekday())})
              <br />
              <strong>
                {'{'}month{'}'}
              </strong>{' '}
              - The current month ({moment.months(moment().month())})
              <br />
              <strong>
                {'{'}year{'}'}
              </strong>{' '}
              - The current year ({moment().year()})
            </Text>
          </Stack.Item>
          <Stack.Item style={{ maxWidth: 600, width: 600 }}>
            <TextField
              value={settings.gpt_vision_system_prompt}
              onChange={event =>
                setSettings({
                  ...settings,
                  gpt_vision_system_prompt: event.currentTarget.value,
                })
              }
              label='GPT Vision - System Prompt'
              multiline
              rows={5}
            />
            <Text variant={'xSmall'} block>
              The GPT vision system prompt sent to the LLM when describing images.
            </Text>
          </Stack.Item>
          <Stack.Item style={{ maxWidth: 600, width: 600 }}>
            <TextField
              value={settings.gpt_vision_user_prompt}
              onChange={event =>
                setSettings({
                  ...settings,
                  gpt_vision_user_prompt: event.currentTarget.value,
                })
              }
              label='GPT Vision - User Prompt'
              multiline
              rows={5}
            />
            <Text variant={'xSmall'} block>
              The GPT vision user prompt sent to the LLM when describing images.
            </Text>
          </Stack.Item>
          <Stack.Item>
            <Stack horizontal tokens={{ childrenGap: 10 }}>
              <PrimaryButton
                onClick={saveSettings}
                text={saving ? 'Saving...' : 'Save'}
                allowDisabledFocus
              />
            </Stack>
          </Stack.Item>
        </Stack>
      </>
    )) || <Spinner size={SpinnerSize.large} />
  );
};

export default PromptSettings;
