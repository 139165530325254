import { FC, createContext, useContext } from 'react';
import { ApiHttpServiceContext } from './ApiHttpService';
import { IAnalyticsJob } from '../../Models/API/IAnalyticsJob';

export interface IAnalyticsService {
  StartAnalytics(): Promise<void | null>;
  StartChatAppAnalytics(chatAppId: number): Promise<void | null>;
  PurgeAllConversations(): Promise<void | null>;
  PurgeAllChatAppConversations(chatAppId: number): Promise<void | null>;
  GetJobs(): Promise<IAnalyticsJob[] | null>;
}

export const analyticsServiceContext = createContext<IAnalyticsService | undefined>(undefined);

const AnalyticsService: FC = ({ children }: any) => {
  const apiHttpService = useContext(ApiHttpServiceContext);

  const controller: string = 'analytics';

  const analyticsService: IAnalyticsService = {
    async StartAnalytics() {
      return await apiHttpService!.Post<void>(`${controller}/start`);
    },
    async StartChatAppAnalytics(chatAppId: number) {
      return await apiHttpService!.Post<void>(`${controller}/start/${chatAppId}`);
    },
    async PurgeAllConversations() {
      return await apiHttpService!.Post<void>(`${controller}/purge`);
    },
    async PurgeAllChatAppConversations(chatAppId: number) {
      return await apiHttpService!.Post<void>(`${controller}/purge/${chatAppId}`);
    },
    async GetJobs() {
      return await apiHttpService!.Get<IAnalyticsJob[]>(`${controller}/jobs`);
    },
  };

  return (
    <analyticsServiceContext.Provider value={analyticsService}>
      {children}
    </analyticsServiceContext.Provider>
  );
};

export default AnalyticsService;
