import {
  Checkbox,
  CommandBar,
  DefaultButton,
  DetailsList,
  IColumn,
  ICommandBarItemProps,
  IObjectWithKey,
  Panel,
  PrimaryButton,
  Selection,
  SelectionMode,
  Spinner,
  SpinnerSize,
  Stack,
  Text,
  TextField,
} from "@fluentui/react";
import React, { FC, useState, useEffect, useContext } from "react";
import { useOutletContext } from "react-router-dom";
import ILibrary, {
  ILibraryMetadata,
} from "../../../../../../../../Models/API/ILibrary";
import { LibraryServiceContext } from "../../../../../../../../Services/API/LibraryService";
import IMetadataField, {
  MetadataFieldTypes,
} from "../../../../../../../../Models/API/IMetadataField";
import MetadataField from "../../../../../../../../Components/Metadata/MetadataField/MetadataField";
import moment from "moment";
import AddMetadataField from "../../../../../../../../Components/Metadata/AddMetadataField/AddMetadataField";
import { trackPageView } from "../../../../../../../../Services/AppInsights";

const columns: IColumn[] = [
  {
    key: "DisplayName",
    name: "Display Name",
    minWidth: 200,
    isResizable: true,
  },
  {
    key: "DefaultValue",
    name: "Default Value",
    minWidth: 200,
    isResizable: true,
  },
  {
    key: "Type",
    name: "Type",
    minWidth: 200,
    isResizable: true,
  },
  {
    key: "Required",
    name: "Required",
    minWidth: 100,
    isResizable: true,
  },
];

const LibraryMetadata: FC = () => {
  const { library, setLibrary } = useOutletContext<{
    library: ILibrary;
    setLibrary: (value: ILibrary) => void;
  }>();
  const libraryService = useContext(LibraryServiceContext);
  const [commandBarButtons, setCommandBarButtons] = useState<
    ICommandBarItemProps[]
  >([]);
  const [metadata, setMetadata] = useState<ILibraryMetadata | null>(
    library.Metadata ?? null
  );
  const [selectedFields, setSelectedFields] = useState<IMetadataField[]>([]);
  const [currentField, setCurrentField] = useState<IMetadataField>();
  const [savingField, setSavingField] = useState<boolean>(false);
  const [hideNewFieldDialog, setHideNewFieldDialog] = useState<boolean>(true);

  // Track page view
  useEffect(() => {
    trackPageView();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Runs when the selected fields change
  useEffect(() => {
    setCommandBarButtons([
      {
        key: "add",
        text: "Add",
        iconProps: { iconName: "Add" },
        subMenuProps: {
          items: [
            {
              key: "field",
              text: "Field",
              iconProps: { iconName: "TextField" },
              onClick: (event, item) => {
                setHideNewFieldDialog(false);
              },
            },
          ],
        },
      },
      {
        key: "edit",
        text: "Edit",
        iconProps: { iconName: "Edit" },
        onClick: (event, item) => {
          setCurrentField(selectedFields[0]);
        },
        disabled: selectedFields.length === 0,
      },
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFields]);

  const onRenderItemColumn = (
    item: IMetadataField,
    index?: number,
    column?: IColumn
  ): any => {
    if (column?.key === "Required") {
      return item.Required ? "Yes" : "No";
    } else if (column?.key === "DefaultValue") {
      switch (item.Type) {
        case "DateTime":
          return item.DefaultValue
            ? moment(item.DefaultValue as string).format("DD MMM yyyy")
            : "";
        case "Boolean":
          return item.DefaultValue ? "Yes" : "No";
        case "Double":
        case "Integer":
        case "String":
          return item.DefaultValue as string;
        case "StringList":
          return item.DefaultValue
            ? (item.DefaultValue as string[])?.join(",")
            : "";
      }
    } else {
      return item![column?.key as keyof IMetadataField];
    }
  };

  const selection = new Selection({
    canSelectItem: (item: IObjectWithKey, index) => {
      return (item as IMetadataField).InternalName!.startsWith("c_");
    },
    onSelectionChanged: () => {
      setSelectedFields(selection.getSelection() as IMetadataField[]);
    },
  });

  const saveField = async () => {
    if (!savingField) {
      setSavingField(true);
      var refreshedLib = await libraryService!.UpdateMetadata(library.Id, [
        {
          InternalName: currentField!.InternalName!,
          DisplayName: currentField!.DisplayName,
          Description: currentField!.Description,
          DefaultValue:
            currentField!.DefaultValue === ""
              ? currentField!.Type === "StringList"
                ? []
                : null
              : currentField!.DefaultValue,
          Required: currentField!.Required,
        },
      ]);
      setSelectedFields([]);
      if (refreshedLib != null) {
        setMetadata(refreshedLib.Metadata!);
        setLibrary(refreshedLib);
      }
      setCurrentField(undefined);
      setSavingField(false);
    }
  };

  const onRenderFooterContent = React.useCallback(
    () => (
      <Stack horizontal tokens={{ childrenGap: 10 }}>
        <DefaultButton onClick={() => setCurrentField(undefined)}>
          Cancel
        </DefaultButton>
        <PrimaryButton
          style={{ minWidth: 80 }}
          onClick={saveField}
          text={savingField ? "" : "Save"}
          disabled={
            !currentField?.DisplayName || currentField?.DisplayName.length < 3
          }
        >
          {savingField && <Spinner size={SpinnerSize.small} />}
        </PrimaryButton>
      </Stack>
    ),
    // eslint-disable-next-line
    [currentField, savingField]
  );

  const addField = async (field: IMetadataField) => {
    const refreshedLib = await libraryService!.AddMetadataField(
      library.Id,
      field
    );
    if (refreshedLib) {
      setMetadata(refreshedLib.Metadata!);
      setLibrary(refreshedLib);
    }
    setHideNewFieldDialog(true);
  };

  return (
    <>
      <Stack verticalFill style={{ minHeight: 0 }}>
        <Stack.Item
          grow
          verticalFill
          style={{ minHeight: 0, overflowY: "auto", position: "relative" }}
        >
          {library.HasCustomMetadata && (
            <CommandBar items={commandBarButtons} ariaLabel="Fields actions" />
          )}
          <DetailsList
            className="metadata-list"
            setKey="items"
            items={metadata?.Fields || []}
            columns={columns}
            selection={selection}
            selectionMode={SelectionMode.single}
            onRenderItemColumn={onRenderItemColumn}
            ariaLabelForGrid="Item details"
            listProps={{ renderedWindowsAhead: 0, renderedWindowsBehind: 0 }}
            styles={{ root: { verticalAlign: "middle" } }}
          />
          {(metadata === undefined ||
            metadata === null ||
            metadata.Fields.length === 0) && (
            <>
              {metadata !== undefined &&
                (metadata === null || metadata!.Fields.length === 0) && (
                  <Text
                    variant="large"
                    block
                    style={{
                      textAlign: "center",
                      opacity: 0.5,
                    }}
                  >
                    No metadata fields found
                  </Text>
                )}
              {metadata === undefined && <Spinner size={SpinnerSize.large} />}
            </>
          )}
        </Stack.Item>
      </Stack>
      <Panel
        isOpen={currentField !== undefined}
        onDismiss={() => setCurrentField(undefined)}
        headerText="Update Field"
        closeButtonAriaLabel="Close"
        onRenderFooterContent={onRenderFooterContent}
        isFooterAtBottom={true}
      >
        {currentField !== undefined && (
          <Stack style={{ marginTop: 20 }} tokens={{ childrenGap: 20 }}>
            <Stack.Item>
              <TextField
                label="Type"
                value={
                  MetadataFieldTypes.find((f) => f.key === currentField!.Type)
                    ?.text
                }
                readOnly
              />
            </Stack.Item>
            <Stack.Item>
              <TextField
                required={true}
                label="Display Name"
                value={currentField!.DisplayName}
                onChange={(evt, newValue) => {
                  setCurrentField({ ...currentField!, DisplayName: newValue! });
                }}
              />
              <Text variant="xSmall">Minimum 3 characters</Text>
            </Stack.Item>
            <Stack.Item>
              <TextField
                label="Description"
                multiline
                rows={3}
                resizable={false}
                value={currentField!.Description}
                onChange={(evt, newValue) => {
                  setCurrentField({ ...currentField!, Description: newValue! });
                }}
              />
            </Stack.Item>
            <Stack.Item>
              <MetadataField
                label="Default Value"
                value={currentField!.DefaultValue}
                setValue={(value) => {
                  setCurrentField({
                    ...currentField!,
                    DefaultValue: value!,
                  });
                }}
                field={currentField!}
              ></MetadataField>
            </Stack.Item>
            <Stack.Item>
              <Checkbox
                label="Required"
                checked={currentField!.Required}
                onChange={(evnt, checked) => {
                  setCurrentField({ ...currentField!, Required: checked! });
                }}
              />
            </Stack.Item>
          </Stack>
        )}
      </Panel>
      <AddMetadataField
        canAddField={(field) =>
          metadata?.Fields.find(
            (f) =>
              f.DisplayName.trim().toLowerCase() ===
              field.DisplayName.trim().toLowerCase()
          ) === undefined
        }
        hidden={hideNewFieldDialog}
        onCancel={() => setHideNewFieldDialog(true)}
        onFieldAdd={addField}
      ></AddMetadataField>
    </>
  );
};

export default LibraryMetadata;
