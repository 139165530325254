import IChatAppHierarchy from '../../../../../../../Models/API/IChatAppHierarchy';
import styles from './HierarchyNode.module.scss';

interface HierarchyNodeProps {
  node: IChatAppHierarchy;
}

const HierarchyNode: React.FC<HierarchyNodeProps> = ({ node }) => {
  return (
    <div className={styles.node}>
      <div className={styles.nodeContent}>{node.DisplayName}</div>
      {node.SearchFunctions.length > 0 && (
        <div className={styles.children}>
          {node.SearchFunctions.map(searchFunction => (
            <div className='node' key={searchFunction.Id}>
              <div className={`${styles.nodeContent} ${styles.searchFunction}`}>
                {searchFunction.DisplayName}
              </div>
            </div>
          ))}
        </div>
      )}
      {node.Functions.length > 0 && (
        <div className={styles.children}>
          {node.Functions.map(func => (
            <div className='node' key={func.Id}>
              <div className={`${styles.nodeContent} ${styles.function}`}>{func.DisplayName}</div>
            </div>
          ))}
        </div>
      )}
      {node.ChildApps.length > 0 && (
        <div className={styles.children}>
          {node.ChildApps.map(child => (
            <HierarchyNode node={child} key={child.Id} />
          ))}
        </div>
      )}
    </div>
  );
};

export default HierarchyNode;
