import { Icon, Stack, StackItem, Toggle } from "@fluentui/react";
import { FC, useContext } from "react";
import { AppCatalogServiceContext } from "../../Services/AppCatalogService";

const DarkModeSwitch: FC = () => {
  const appCatalogService = useContext(AppCatalogServiceContext);

  const _onChange = (
    event: React.MouseEvent<HTMLElement>,
    checked?: boolean
  ) => {
    appCatalogService?.SwitchTheme(!checked!);
  };

  const icon = () => {
    return appCatalogService?.darkMode ? (
      <Icon iconName="ClearNight" />
    ) : (
      <Icon iconName="Sunny" />
    );
  };

  return (
    <>
      <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 5 }}>
        <StackItem>
          <Toggle
            styles={{ root: { marginBottom: 0 } }}
            inlineLabel
            onChange={_onChange}
            checked={!appCatalogService?.darkMode}
          />
        </StackItem>
        <StackItem style={{ display: "flex" }}>{icon()}</StackItem>
      </Stack>
    </>
  );
};

export default DarkModeSwitch;
