import {
  PrimaryButton,
  Slider,
  Spinner,
  SpinnerSize,
  Stack,
  Text,
  TextField,
  Toggle,
} from '@fluentui/react';
import { FC, useContext, useEffect, useState } from 'react';
import { SettingsServiceContext } from '../../../Services/API/SettingsService';
import ISettings from '../../../Models/API/ISettings';
import { DialogServiceContext } from '../../../Services/Dialogs/DialogService';
import { trackPageView } from '../../../Services/AppInsights';

const SearchSettings: FC = () => {
  const settingsService = useContext(SettingsServiceContext);

  const dialogService = useContext(DialogServiceContext);
  const [settings, setSettings] = useState<ISettings | null>(null);
  const [saving, setSaving] = useState<boolean>(false);
  const [isMounted, setIsMounted] = useState<boolean>(true);

  // Track page view
  useEffect(() => {
    trackPageView();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const execute = async () => {
      var reply = await settingsService!.Get();
      if (isMounted) setSettings(reply);
    };
    execute();
    return () => {
      setIsMounted(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const saveSettings = async () => {
    setSaving(true);
    await settingsService?.Save(settings!);
    dialogService?.showSuccessDialog('Settings saved', 'All settings have been saved.');
    setSaving(false);
  };

  return (
    (settings && (
      <>
        <Stack tokens={{ childrenGap: 40 }}>
          <Stack.Item style={{ maxWidth: 600 }}>
            <Toggle
              label='Send filtered citations ONLY to chat app'
              checked={settings.search_use_source_filter}
              onChange={(evt, checked) =>
                setSettings({ ...settings, search_use_source_filter: checked! })
              }
            />
            <small>
              If checked, only the citations with a score above the defined threshold below will be
              sent to the chat app as citations.
            </small>
          </Stack.Item>
          <Stack.Item style={{ maxWidth: 300, width: 300 }}>
            <Slider
              label='Search score threshold'
              max={1}
              min={0}
              step={0.1}
              value={settings!.search_score_threshold}
              showValue
              onChange={val => setSettings({ ...settings, search_score_threshold: val })}
            />
            <Text variant={'xSmall'} block>
              The minimum score required for a search result to be considered relevant.
            </Text>
          </Stack.Item>
          <Stack.Item style={{ maxWidth: 600, width: 600, marginTop: 20 }}>
            <TextField
              value={settings.search_filter_sources_prompt}
              onChange={event =>
                setSettings({
                  ...settings,
                  search_filter_sources_prompt: event.currentTarget.value,
                })
              }
              label='Filter sources prompt'
              multiline
              rows={20}
            />
            <Text variant={'xSmall'} block>
              The prompt used to filter relevant sources in the search.
              <br />
              <strong>
                {'{'}citations{'}'}
              </strong>{' '}
              - The citations returned by the search
              <br />
              <strong>
                {'{'}conversation{'}'}
              </strong>{' '}
              - The conversation history
            </Text>
          </Stack.Item>
        </Stack>

        <Stack style={{ marginTop: 20 }}>
          <Stack.Item>
            <Stack horizontal tokens={{ childrenGap: 10 }}>
              <PrimaryButton
                onClick={saveSettings}
                text={saving ? 'Saving...' : 'Save'}
                allowDisabledFocus
              />
            </Stack>
          </Stack.Item>
        </Stack>
      </>
    )) || <Spinner size={SpinnerSize.large} />
  );
};

export default SearchSettings;
