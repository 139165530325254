import { FC, useContext, useState } from 'react';
import {
  IAgentAppSearchFunction,
  IAgentAppSearchFunctionResults,
  IAgentPropertyType,
} from '../../../../../../Models/API/IAgentAppFunction';
import {
  agentAppServiceContext,
  INewAgentAppSearchFunction,
} from '../../../../../../Services/API/AgentAppService';
import {
  Label,
  PrimaryButton,
  Separator,
  Spinner,
  SpinnerSize,
  Stack,
  Text,
  TextField,
} from '@fluentui/react';
import AuthenticationService from '../../../../../../Services/AuthenticationService';
import { useOutletContext } from 'react-router-dom';
import IAgentApp from '../../../../../../Models/API/IAgentApp';

export interface IFunctionsFormProps {
  agentFunction: IAgentAppSearchFunction | INewAgentAppSearchFunction;
  setAgentFunction(agentFunction: IAgentAppSearchFunction | INewAgentAppSearchFunction): void;
}

export interface Property {
  internalId: number;
  name: string;
  type: IAgentPropertyType;
  description: string;
  required: boolean;
}

const SearchFunctionForm: FC<IFunctionsFormProps> = ({ agentFunction, setAgentFunction }) => {
  const { agentApp } = useOutletContext<{
    agentApp: IAgentApp;
  }>();
  const agentAppsService = useContext(agentAppServiceContext);

  const [executing, setExecuting] = useState<boolean>(false);
  const [valueForTest, setValueForTest] = useState<string>('');
  const [functionResults, setFunctionResults] = useState<string>('');

  const testFunction = async () => {
    setExecuting(true);
    const userId = AuthenticationService.Default.Account?.localAccountId!;
    const results = await agentAppsService?.ExecuteSearchFunction(
      agentApp.Id,
      (agentFunction as IAgentAppSearchFunction).Id,
      valueForTest,
      userId
    );
    if (results) {
      setFunctionResults(JSON.stringify(results, null, 2));
    } else {
      setFunctionResults('Error executing function');
    }
    setExecuting(false);
  };

  return (
    <>
      {agentFunction !== undefined && (
        <Stack style={{ marginTop: 20 }} tokens={{ childrenGap: 20 }}>
          <Stack.Item>
            <TextField
              required={true}
              label='Display Name'
              value={agentFunction!.DisplayName}
              onChange={(evt, newValue) => {
                setAgentFunction({ ...agentFunction!, DisplayName: newValue! });
              }}
            />
            <Text style={{ marginTop: 4 }} variant='xSmall' block>
              Minimum 5 characters.
            </Text>
            <Text variant='xSmall' block>
              Can only contain letters, numbers and spaces.
            </Text>
          </Stack.Item>
          <Stack.Item>
            <TextField
              required={true}
              label='Description'
              multiline
              rows={3}
              resizable={false}
              value={agentFunction!.Description}
              onChange={(evt, newValue) => {
                setAgentFunction({ ...agentFunction!, Description: newValue! });
              }}
            />
            <Text variant='xSmall'>Minimum 20 characters</Text>
          </Stack.Item>

          <Stack.Item>
            <TextField
              required={true}
              label='Action Url'
              value={agentFunction!.ActionUrl}
              onChange={(evt, newValue) => {
                setAgentFunction({ ...agentFunction!, ActionUrl: newValue! });
              }}
            ></TextField>
          </Stack.Item>
          <Stack.Item>
            <Label>Top Results</Label>
            <TextField
              styles={{ root: { width: 80 } }}
              required={true}
              type='number'
              value={agentFunction!.TopResults.toString()}
              onChange={(evt, newValue) => {
                setAgentFunction({
                  ...agentFunction!,
                  TopResults: Number(newValue!),
                });
              }}
            ></TextField>
            <Text variant='xSmall'>
              Amount of results from the API to be used in the system prompt.
            </Text>
          </Stack.Item>
          {agentFunction!.InputParameters && (
            <Stack.Item>
              <TextField
                label='Input parameters'
                readOnly
                multiline
                rows={5}
                min={1}
                resizable={false}
                value={agentFunction!.InputParameters}
                onChange={(evt, newValue) => {
                  setAgentFunction({
                    ...agentFunction!,
                    InputParameters: newValue!,
                  });
                }}
              />
            </Stack.Item>
          )}

          {agentFunction!.OutputParameters && (
            <Stack.Item>
              <TextField
                readOnly
                label='Output parameters'
                multiline
                rows={5}
                resizable={false}
                value={agentFunction!.OutputParameters}
                onChange={(evt, newValue) => {
                  setAgentFunction({
                    ...agentFunction!,
                    OutputParameters: newValue!,
                  });
                }}
              />
            </Stack.Item>
          )}

          {agentFunction!.ActionUrl &&
            agentFunction!.InputParameters &&
            agentFunction!.OutputParameters && (
              <article>
                <header style={{ marginTop: '1rem', marginBottom: '1rem' }}>
                  <Text variant={'large'}>Test function</Text>
                </header>
                <Stack.Item>
                  <Stack.Item
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'flex-end',
                    }}
                  >
                    <TextField
                      required={true}
                      label='Search Query'
                      value={valueForTest}
                      style={{ width: '300px' }}
                      onChange={(evt, newValue) => {
                        setValueForTest(newValue!);
                      }}
                    />
                    <PrimaryButton
                      style={{ minWidth: 80 }}
                      onClick={testFunction}
                      disabled={!valueForTest && valueForTest.length < 5}
                      text={executing ? '' : 'Test'}
                    >
                      {executing && <Spinner size={SpinnerSize.small} />}
                    </PrimaryButton>
                  </Stack.Item>

                  <TextField
                    readOnly
                    label='Function results'
                    multiline
                    rows={5}
                    resizable={true}
                    value={functionResults}
                    onChange={(evt, newValue) => {
                      setValueForTest(newValue!);
                    }}
                  />
                </Stack.Item>
              </article>
            )}
        </Stack>
      )}
    </>
  );
};

export default SearchFunctionForm;
