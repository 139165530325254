import "./CatalogStart.scss";
import { Layer, Spinner, SpinnerSize, Stack } from "@fluentui/react";
import { Navigation as CatalogNavigation } from "../../../Components/Catalog/Navigation/Navigation";
import { Footer as CatalogFooter } from "../../../Components/Catalog/Footer/Footer";
import Toast from "../../../Components/Toast/Toast";
import { AppCatalogServiceContext } from "../../../Services/AppCatalogService";
import { FC, useContext, useEffect, useState } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import IAppCatalog from "../../../Models/API/IAppCatalog";
import { catalogServiceContext } from "../../../Services/API/CatalogService";
import Role from "../../../Components/Role/Role";
import BaseApp from "../../Catalog/BaseApp/BaseApp";
import { Catalog } from "../../Catalog/Catalog";
import { useBoolean } from "@fluentui/react-hooks";
import ConfigurationService from "../../../Services/ConfigurationService";

const maxWebsiteWidth = 1440;

export interface IAppStartProps {
  loading: boolean;
}

export const CatalogStart: FC<IAppStartProps> = ({ loading }: any) => {
  const appCatalogService = useContext(AppCatalogServiceContext);
  const catalogService = useContext(catalogServiceContext);
  const [internalLoading, setInternalLoading] = useState<boolean>(true);
  const [catalog, setCatalog] = useState<IAppCatalog>();
  const location = useLocation();

  const [isOpen, { setTrue: openPanel, setFalse: dismissPanel }] =
    useBoolean(false);

  useEffect(() => {
    const execute = async () => {
      setInternalLoading(true);

      const catalogId = Number(location.pathname.split("/")[2]);

      if (!catalog || catalog.Id !== catalogId) {
        const tempCatalog = await catalogService?.Get(catalogId);

        if (tempCatalog) {
          document.title = `${ConfigurationService.Default.Configuration.PageTitle} - ${tempCatalog.DisplayName}`;
          setCatalog(tempCatalog);
          let appLogo = undefined;
          if (tempCatalog.LogoUrl) {
            appLogo = await catalogService?.GetLogo(Number(catalogId));
          }

          await appCatalogService?.ChangeTheme({
            title: tempCatalog.DisplayName,
            logoBase64: appLogo,
            backgroundColor: tempCatalog.BackgroundColor,
            primaryColor: tempCatalog.PrimaryColor,
            secondaryColor: tempCatalog.SecondaryColor,
            subtitle: "",
          });
        }
      }
      setInternalLoading(false);
    };
    if (!loading) {
      execute();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, loading]);

  return (
    <>
      {(!loading && !internalLoading && catalog && (
        <>
          <Layer>
            <Stack horizontalAlign="end">
              <Stack.Item>
                <Toast />
              </Stack.Item>
            </Stack>
          </Layer>
          <Stack
            horizontalAlign="center"
            grow
            verticalFill
            style={{ height: "100%", minHeight: 0 }}
          >
            <Stack style={{ maxWidth: maxWebsiteWidth, width: "100%" }}>
              <CatalogNavigation catalog={catalog} openPanel={openPanel} />
            </Stack>
            <Stack
              grow
              verticalFill
              style={{
                minHeight: 0,
                maxWidth: maxWebsiteWidth,
                width: "100%",
              }}
              tokens={{ padding: "0 2rem" }}
            >
              <Routes>
                <Route
                  path=""
                  element={
                    <Role
                      children={
                        <Catalog isOpen={isOpen} dismissPanel={dismissPanel} />
                      }
                    />
                  }
                >
                  <Route
                    path=":type/:appId"
                    element={<Role children={<BaseApp />} />}
                  ></Route>
                </Route>
              </Routes>
            </Stack>
            <Stack style={{ maxWidth: maxWebsiteWidth, width: "100%" }}>
              <CatalogFooter catalog={catalog} />
            </Stack>
          </Stack>
        </>
      )) || (
        <Stack style={{ height: "100%" }} verticalAlign="center">
          <Spinner size={SpinnerSize.large} />
        </Stack>
      )}
    </>
  );
};
