import { FC, useContext, useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import IChatApp from '../../../../../../Models/API/IChatApp';
import { chatAppServiceContext } from '../../../../../../Services/API/ChatAppService';
import {
  Stack,
  CommandBar,
  SearchBox,
  DetailsList,
  SelectionMode,
  Spinner,
  SpinnerSize,
  Dialog,
  DialogType,
  DialogFooter,
  PrimaryButton,
  DefaultButton,
  ICommandBarItemProps,
  IColumn,
  Text,
  Selection,
  Icon,
  ComboBox,
  IComboBox,
  IComboBoxOption,
  IconButton,
  Modal,
} from '@fluentui/react';
import { removeDiacritics } from '../../../../../../Utilities/Strings';
import { trackPageView } from '../../../../../../Services/AppInsights';
import ConfigurationService from '../../../../../../Services/ConfigurationService';
import ChatAppHierarchy from './Hierarchy/ChatAppHierarchy';

const shimmerColumns: IColumn[] = [
  {
    key: 'Icon',
    name: '',
    minWidth: 20,
    maxWidth: 20,
    isResizable: true,
  },
  {
    key: 'DisplayName',
    name: 'DisplayName',
    minWidth: 200,
    maxWidth: 300,
    isResizable: true,
  },
  {
    key: 'Description',
    name: 'Description',
    minWidth: 400,
    isResizable: true,
  },
];

export interface IChatAppsChatAppsProps {}

const ChatAppsChats: FC<IChatAppsChatAppsProps> = () => {
  const chatAppsService = useContext(chatAppServiceContext);
  const { chatApp } = useOutletContext<{ chatApp: IChatApp }>();

  const [chatAppsForCombo, setChatAppsForCombo] = useState<IComboBoxOption[]>([]);

  const [allChatApps, setAllChatApps] = useState<IChatApp[] | null>(null);
  const [chatAppChats, setChatAppChats] = useState<IChatApp[] | null>(null);
  const [filteredChatApps, setFilteredChatApps] = useState<IChatApp[] | null | undefined>(
    undefined
  );

  const [filterKeyword, setFilterKeyword] = useState<string | undefined>(undefined);
  const [selectedChatApps, setSelectedChatApps] = useState<IChatApp[]>([]);
  const [commandBarButtons, setCommandBarButtons] = useState<ICommandBarItemProps[]>([]);
  const [hideRemoveChatAppDialog, setHideRemoveChatAppDialog] = useState<boolean>(true);
  const [hideAddChatAppDialog, setHideAddChatAppDialog] = useState<boolean>(true);
  const [selectedComboChatApp, setSelectedComboChatApp] = useState<string | undefined>(undefined);
  const [addingChatApps, setAddingChatApps] = useState<boolean>(false);

  const [showHierarchyModal, setShowHierarchyModal] = useState<boolean>(false);

  // Track page view
  useEffect(() => {
    trackPageView();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const reloadChatApps = async () => {
    setChatAppChats([]);
    setFilteredChatApps(undefined);
    let chatAppChats = await chatAppsService!.GetChatAppChats(chatApp.Id);
    chatAppChats = chatAppChats!.sort((permA, permB) =>
      permA.DisplayName.localeCompare(permB.DisplayName)
    );
    setFilteredChatApps(chatAppChats);
    setChatAppChats(chatAppChats);

    const allChatApps = await chatAppsService?.GetChatAppForLink(chatApp.Id);
    if (allChatApps) {
      setAllChatApps(allChatApps);
      const toDisplay = allChatApps.filter(x => !chatAppChats?.some(y => y.Id === x.Id));
      setChatAppsForCombo(
        toDisplay.map(x => {
          return {
            key: x.InternalName,
            text: x.DisplayName,
          };
        })
      );
    }
    setSelectedComboChatApp(undefined);
  };

  const onRenderItemColumn = (item: IChatApp, index?: number, column?: IColumn): any => {
    if (column?.key === 'Icon') {
      return <Icon style={{ fontSize: '1rem' }} iconName='Chat' />;
    } else if (column?.key === 'DisplayName') {
      return item.DisplayName;
    } else if (column?.key === 'Description') {
      return item.Description;
    } else {
      return item![column?.key as keyof IChatApp];
    }
  };

  const selection = new Selection({
    canSelectItem(item, index) {
      return true;
    },
    onSelectionChanged: () => {
      setSelectedChatApps(selection.getSelection() as IChatApp[]);
    },
  });

  const resetState = (): void => {
    setHideRemoveChatAppDialog(true);
    setChatAppChats([]);
    setFilteredChatApps(undefined);
    setSelectedChatApps([]);
    setFilterKeyword(undefined);
  };

  const removeChatApps = async (): Promise<void> => {
    const promises: (Promise<void | null> | undefined)[] = [];
    const selectedChats = [...selectedChatApps];
    resetState();
    for (let i = 0; i < selectedChats.length; i++) {
      const perm = selectedChats[i];
      promises.push(chatAppsService!.DeleteChatAppChat(chatApp.Id, perm.Id));
    }
    await Promise.allSettled(promises);
    reloadChatApps();
  };

  const onAddChatAppsComboChange = (
    event: React.FormEvent<IComboBox>,
    option?: IComboBoxOption,
    index?: number,
    value?: string
  ): void => {
    if (option) {
      setSelectedComboChatApp(option.key as string);
    }
  };

  const addChatAppsToChatApp = async () => {
    if (selectedComboChatApp && !addingChatApps) {
      setAddingChatApps(true);
      const childChatApp = allChatApps?.find(x => x.InternalName === selectedComboChatApp);
      if (childChatApp) {
        await chatAppsService!.AddChatAppChat(chatApp.Id, childChatApp.Id);
      }
      reloadChatApps();
      setAddingChatApps(false);
      setHideAddChatAppDialog(true);
    }
  };

  useEffect(() => {
    setCommandBarButtons([
      {
        key: 'add',
        text: 'Add Chat App',
        iconProps: { iconName: 'CannedChat' },
        onClick: (event, item) => {
          setHideAddChatAppDialog(false);
        },
      },
      {
        key: 'remove',
        text: 'Remove Chat App',
        iconProps: { iconName: 'Delete' },
        onClick: (event, item) => {
          setHideRemoveChatAppDialog(false);
        },
        disabled: selectedChatApps.length === 0,
      },
      {
        key: 'refresh',
        text: 'Refresh',
        iconProps: { iconName: 'Refresh' },
        onClick: (event, item) => {
          reloadChatApps();
        },
      },
      {
        key: 'hierarchy',
        text: 'Hierarchy',
        iconProps: { iconName: 'FlowChart' },
        onClick: (event, item) => {
          setShowHierarchyModal(true);
        },
      },
    ]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedChatApps, chatApp]);

  // Runs when the filter keyword changes
  useEffect(() => {
    if (chatAppChats && filterKeyword !== undefined) {
      setFilteredChatApps(
        filterKeyword
          ? chatAppChats?.filter(perm => {
              return (
                removeDiacritics(perm.DisplayName.toLowerCase()).indexOf(
                  removeDiacritics(filterKeyword.toLowerCase())
                ) !== -1
              );
            })
          : chatAppChats
      );
    }
  }, [filterKeyword, chatAppChats]);

  useEffect(() => {
    document.title = `${ConfigurationService.Default.Configuration.PageTitle} - ${chatApp.DisplayName} Chat Apps`;
    const execute = async () => {
      reloadChatApps();
    };
    execute();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Stack verticalFill style={{ minHeight: 0 }}>
        <Stack.Item>
          <CommandBar items={commandBarButtons} ariaLabel='Chat Apps actions' />
          <SearchBox
            value={filterKeyword}
            onChange={(evt, newValue) => {
              setFilterKeyword(newValue);
            }}
            placeholder='Filter by chat app name'
            iconProps={{ iconName: 'Filter' }}
            underlined={true}
          />
        </Stack.Item>
        <Stack.Item verticalFill style={{ overflowY: 'auto', minHeight: 0, position: 'relative' }}>
          <DetailsList
            setKey='items'
            items={filteredChatApps || []}
            columns={shimmerColumns}
            selection={selection}
            selectionMode={SelectionMode.multiple}
            onRenderItemColumn={onRenderItemColumn}
            ariaLabelForGrid='Item details'
            listProps={{ renderedWindowsAhead: 0, renderedWindowsBehind: 0 }}
            className='documents-list'
          />
          {(filteredChatApps === undefined || filteredChatApps?.length === 0) && (
            <>
              {filteredChatApps?.length === 0 && (
                <Text
                  variant='large'
                  block
                  style={{
                    textAlign: 'center',
                    opacity: 0.5,
                  }}
                >
                  No Chat Apps found
                </Text>
              )}
              {filteredChatApps === undefined && <Spinner size={SpinnerSize.large} />}
            </>
          )}
        </Stack.Item>
      </Stack>
      <Dialog
        hidden={hideRemoveChatAppDialog}
        onDismiss={() => setHideRemoveChatAppDialog(true)}
        modalProps={{ isBlocking: true, styles: { main: { maxWidth: 450 } } }}
        dialogContentProps={{
          type: DialogType.normal,
          title: 'Remove Chat Apps',
          subText:
            'Are you sure you want to remove the chat apps for the current selected Chat App?',
        }}
      >
        <Stack tokens={{ childrenGap: 5 }}>
          {selectedChatApps.map(perm => {
            return (
              <Text key={perm.InternalName} block>
                {perm.DisplayName}
              </Text>
            );
          })}
        </Stack>
        <DialogFooter>
          <PrimaryButton onClick={removeChatApps} text='Remove' />
          <DefaultButton onClick={() => setHideRemoveChatAppDialog(true)} text='Cancel' />
        </DialogFooter>
      </Dialog>

      <Dialog
        hidden={hideAddChatAppDialog}
        onDismiss={() => setHideAddChatAppDialog(true)}
        modalProps={{
          isBlocking: true,
          styles: {
            main: { minWidth: '450px !important', maxWidth: 450 },
          },
        }}
        dialogContentProps={{
          type: DialogType.largeHeader,
          title: 'Add Chat App',
        }}
      >
        <Stack tokens={{ childrenGap: 10 }}>
          <Stack.Item>
            <ComboBox
              label='Chat Apps'
              allowFreeform={false}
              autoComplete={'on'}
              options={chatAppsForCombo}
              onChange={onAddChatAppsComboChange}
            />
          </Stack.Item>
        </Stack>
        <DialogFooter>
          <PrimaryButton
            style={{ minWidth: 150 }}
            disabled={!selectedComboChatApp}
            onClick={addChatAppsToChatApp}
            text={addingChatApps ? '' : 'Add Chat App'}
          >
            {addingChatApps && <Spinner size={SpinnerSize.small} />}
          </PrimaryButton>
          <DefaultButton onClick={() => setHideAddChatAppDialog(true)} text='Cancel' />
        </DialogFooter>
      </Dialog>
      <Modal
        isOpen={showHierarchyModal}
        onDismiss={() => setShowHierarchyModal(false)}
        isBlocking={false}
      >
        <div>
          <h2>{chatApp.DisplayName}</h2>
          <IconButton ariaLabel='Close popup modal' onClick={() => setShowHierarchyModal(false)} />
        </div>
        <div>
          <ChatAppHierarchy />
        </div>
      </Modal>
    </>
  );
};

export default ChatAppsChats;
