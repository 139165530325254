import { FC, useContext, useEffect, useState } from "react";
import {
  IToastMessage,
  ToastServiceContext,
} from "../../Services/ToastService";
import { Callout, Text, mergeStyleSets } from "@fluentui/react";
import { AppCatalogServiceContext } from "../../Services/AppCatalogService";

const Toast: FC = () => {
  const appCatalogService = useContext(AppCatalogServiceContext);
  const theme = appCatalogService?.GetCurrentTheme();

  const styles = mergeStyleSets({
    info: {
      padding: "10px 14px",
      backgroundColor: theme!.palette.neutralPrimaryAlt,
    },
    infoText: {
      color: theme!.palette.white,
    },
    success: {
      padding: "10px 14px",
      backgroundColor: theme!.palette.themePrimary,
    },
    error: {
      padding: "10px 14px",
      backgroundColor: theme!.palette.redDark,
    },
  });

  const liveTimeInSeconds = 5;
  const toastService = useContext(ToastServiceContext);
  const [messages, setMessages] = useState<Array<IToastMessage>>(
    new Array<IToastMessage>()
  );

  useEffect(() => {
    function stuff(message: IToastMessage): void {
      setMessages((prev) => prev.concat(message));
      setTimeout(() => {
        setMessages((prev) => prev.filter((m, i) => i > 0));
      }, liveTimeInSeconds * 1000);
    }
    toastService?.AddSubscriber(stuff);
    return () => {
      toastService?.RemoveSubscriber(stuff);
    };
  });

  return (
    <div>
      <span id="calloutAnchor"></span>
      {messages?.map((message, i) => {
        return (
          <Callout
            id={`callout_${i}`}
            target={i === 0 ? "#calloutAnchor" : `#callout_${i - 1}`}
            className={
              message.type === "info"
                ? styles.info
                : message.type === "success"
                ? styles.success
                : styles.error
            }
            role="dialog"
            isBeakVisible={false}
            ariaLabelledBy={`callout_text_${i}`}
            alignTargetEdge={true}
            shouldDismissOnWindowFocus={true}
            setInitialFocus
            key={`callout_${i}`}
            gapSpace={10}
            styles={{ calloutMain: { backgroundColor: "transparent" } }}
          >
            <Text
              style={{ color: "white" }}
              className={message.type === "info" ? styles.infoText : ""}
              block
              id={`callout_text_${i}`}
            >
              {message.message}
            </Text>
          </Callout>
        );
      })}
    </div>
  );
};

export default Toast;
