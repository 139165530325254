import { Link, Stack, TooltipHost } from '@fluentui/react';
import { FC } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import ConfigurationService from '../../../Services/ConfigurationService';
import './Footer.scss';
import IAppCatalog from '../../../Models/API/IAppCatalog';

interface IFooterProps {
  catalog: IAppCatalog;
}

const Footer: FC<IFooterProps> = ({ catalog }) => {
  const navigation = useNavigate();
  const location = useLocation();

  const contactSupport = (): void => {
    const mail = catalog.OwnerEmails.map(email => email).join(';');
    const subject = `${ConfigurationService.Default.Configuration.Contact.ContactSupportSubject} - ${catalog.DisplayName}`;
    const encodedSubject = encodeURIComponent(subject);
    window.open(`mailto:${mail}?subject=${encodedSubject}`);
  };

  return (
    <Stack horizontal tokens={{ padding: 16 }}>
      <Stack.Item grow>
        <TooltipHost content={catalog.OwnerNames.map(name => name).join(', ')} id={'tooltipOwners'}>
          <Link target='_blank' onClick={contactSupport} className='ms-fontColor-themePrimary'>
            Support
          </Link>
        </TooltipHost>
      </Stack.Item>
      <Stack.Item align='end'>
        <Link
          style={{ textDecoration: 'none !important' }}
          onClick={() => navigation('/versions', { replace: true })}
          className={`ms-fontColor-themePrimary ${
            location.pathname === '/versions' ? 'active' : ''
          }`}
        >
          Latest Release: {ConfigurationService.Default.Configuration.AppVersionDate} v
          {ConfigurationService.Default.Configuration.AppVersion}
        </Link>
      </Stack.Item>
    </Stack>
  );
};

export { Footer };
