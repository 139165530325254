import { FC, useEffect, useState } from "react";
import "./Method.scss";
import { useParams } from "react-router-dom";
import swagger from "./../swagger.json";
import {
  Pivot,
  PivotItem,
  Stack,
  StackItem,
  Text,
  Link,
} from "@fluentui/react";
import ConfigurationService from "../../../Services/ConfigurationService";

const Method: FC = () => {
  const params = useParams();
  const [pathObject, setPathObject] = useState<any>();
  const [method, setMethod] = useState<string>();
  const [path, setPath] = useState<string>();
  const [currentKey, setCurrentKey] = useState<string>("request");

  useEffect(() => {
    let tempPath = `/${params.path?.replaceAll("-", "/")}`;
    const method = tempPath.split("/").pop();
    tempPath = tempPath.split("/").slice(0, -1).join("/");
    const pathObject = (swagger as any).paths[tempPath][method!];
    setPathObject(pathObject);
    setMethod(method);
    setPath(tempPath);
    if (!currentKey) setCurrentKey("request");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  const contentType = (): string | undefined => {
    if (pathObject.requestBody) {
      var types = Object.keys(pathObject.requestBody.content);
      if (types.indexOf("application/json") > -1) {
        return "application/json";
      }
      return types[0];
    }
  };

  const responseContentType = (): string | undefined => {
    if (
      pathObject.responses &&
      pathObject.responses["200"] &&
      pathObject.responses["200"].content
    ) {
      var types = Object.keys(pathObject.responses["200"].content);
      if (types.indexOf("application/json") > -1) {
        return "application/json";
      }
      return types[0];
    }
  };

  const contentTypeSchemaName = (): string => {
    if (pathObject.requestBody) {
      var types = Object.keys(pathObject.requestBody.content);
      if (types.indexOf("application/json") > -1) {
        if (
          pathObject.requestBody.content["application/json"].schema["$ref"] ===
          undefined
        ) {
          return "Parameters";
        }
        return pathObject.requestBody.content["application/json"].schema[
          "$ref"
        ].split("/")[
          pathObject.requestBody.content["application/json"].schema[
            "$ref"
          ].split("/").length - 1
        ];
      }
      return "Form Data";
    }
    return "";
  };

  const responseContentTypeSchemaName = (): string => {
    if (pathObject.responses && pathObject.responses["200"]) {
      var types = Object.keys(pathObject.responses["200"].content);
      if (types.indexOf("application/json") > -1) {
        if (
          pathObject.responses["200"].content["application/json"].schema["$ref"]
        ) {
          return pathObject.responses["200"].content["application/json"].schema[
            "$ref"
          ].split("/")[
            pathObject.responses["200"].content["application/json"].schema[
              "$ref"
            ].split("/").length - 1
          ];
        } else if (
          pathObject.responses["200"].content["application/json"].schema.items
        ) {
          return `${
            pathObject.responses["200"].content[
              "application/json"
            ].schema.items["$ref"].split("/")[
              pathObject.responses["200"].content[
                "application/json"
              ].schema.items["$ref"].split("/").length - 1
            ]
          }`;
        } else {
          return "Response Data";
        }
      }
      return "Form Data";
    }
    return "";
  };

  const contentTypeSchema = (): any => {
    if (pathObject.requestBody) {
      var types = Object.keys(pathObject.requestBody.content);
      if (types.indexOf("application/json") > -1) {
        if (
          pathObject.requestBody.content["application/json"].schema["$ref"] ===
          undefined
        ) {
          return pathObject.requestBody.content["application/json"].schema
            .properties;
        }
        const schemaName =
          pathObject.requestBody.content["application/json"].schema[
            "$ref"
          ].split("/")[
            pathObject.requestBody.content["application/json"].schema[
              "$ref"
            ].split("/").length - 1
          ];
        if ((swagger.components.schemas as any)[schemaName].properties) {
          return (swagger.components.schemas as any)[schemaName].properties;
        } else {
          return {
            additionalProp1: {
              type: "string",
              description: "",
            },
            additionalProp2: {
              type: "string",
              description: "",
            },
            additionalProp3: {
              type: "string",
              description: "",
            },
          };
        }
      }
      return pathObject.requestBody.content[types[0]].schema.properties;
    }
  };

  const responseContentTypeSchema = (): any => {
    if (pathObject.responses && pathObject.responses["200"]) {
      var types = Object.keys(pathObject.responses["200"].content);
      if (types.indexOf("application/json") > -1) {
        let schemaName = "";
        if (
          pathObject.responses["200"].content["application/json"].schema["$ref"]
        ) {
          schemaName =
            pathObject.responses["200"].content["application/json"].schema[
              "$ref"
            ].split("/")[
              pathObject.responses["200"].content["application/json"].schema[
                "$ref"
              ].split("/").length - 1
            ];
        } else {
          return (
            pathObject.responses["200"].content["application/json"].schema
              .properties ??
            pathObject.responses["200"].content["application/json"].schema
          );
        }
        if ((swagger.components.schemas as any)[schemaName].properties) {
          return (swagger.components.schemas as any)[schemaName].properties;
        } else {
          return {
            additionalProp1: {
              type: "string",
              description: "",
            },
            additionalProp2: {
              type: "string",
              description: "",
            },
            additionalProp3: {
              type: "string",
              description: "",
            },
          };
        }
      } else if (types.indexOf("application/octet-stream") > -1) {
        return pathObject.responses["200"].content[types[0]].schema;
      }
      return pathObject.responses["200"].content[types[0]].schema.properties;
    }
  };

  const exampleValue = (schema: any): string => {
    // Treat arrays differently
    if (schema.type === "array") {
      return JSON.stringify([exampleProperty(schema.items)], null, 2);
    } else if (schema.type === "string") {
      return JSON.stringify(
        `string${schema.format ? `(${schema.format})` : ""}`,
        null,
        2
      );
    }
    return JSON.stringify(exampleObject(schema), null, 2);
  };

  const exampleObject = (properties: any): any => {
    const keys = Object.keys(properties);
    let obj: any = {};
    for (var i = 0; i < keys.length; i++) {
      exampleProperty(properties[keys[i]], keys[i], obj);
    }
    return obj;
  };

  const exampleProperty = (
    properties: any,
    propertyName?: string,
    obj?: any
  ): any => {
    let output: any;

    if (properties.type === "integer") output = 0;
    if (properties.type === "number" && properties.format === "double")
      output = 0.0;
    if (properties.type === "string") output = "string";
    if (properties.type === "string" && properties.enum)
      output = properties.enum.join(" | ");
    if (properties.type === "boolean") output = false;
    if (properties.type === "array") {
      output = [exampleProperty(properties.items)];
    }
    if (properties.$ref) {
      output = {};
      const schemaName =
        properties.$ref.split("/")[properties.$ref.split("/").length - 1];
      var props = (swagger.components.schemas as any)[schemaName]
        .properties ?? {
        additionalProp1: {
          type: "string",
          description: "",
        },
        additionalProp2: {
          type: "string",
          description: "",
        },
        additionalProp3: {
          type: "string",
          description: "",
        },
      };
      var keys = Object.keys(props);
      for (var i = 0; i < keys.length; i++) {
        exampleProperty(props[keys[i]], keys[i], output);
      }
    }

    if (propertyName && obj) {
      obj[propertyName] = output;
      return obj;
    }

    return output;
  };

  const propertyType = (property: any): React.ReactFragment => {
    if (property.type === "array") {
      return <Text>{propertyType(property.items)}[]</Text>;
    }

    if (property.type === "integer" || property.type === "number")
      return <Text>{property.format}</Text>;

    if (property.type === "string")
      return (
        <Text>
          string
          {property.format && property.format === "binary" && (
            <>({property.format})</>
          )}
        </Text>
      );

    if (property.type === "boolean") return <Text>boolean</Text>;

    if (property.type === "object") return <Text>{property.type}</Text>;

    if (property.$ref)
      return (
        <Link
          href={`#${
            property.$ref.split("/")[property.$ref.split("/").length - 1]
          }`}
        >
          {property.$ref.split("/")[property.$ref.split("/").length - 1]}
        </Link>
      );

    return <>object</>;
  };

  const renderTable = (schema: any): React.ReactFragment => {
    return (
      <table>
        <thead>
          <tr>
            <th style={{ width: "15%" }}>Parameter</th>
            <th style={{ width: "25%", textAlign: "center" }}>Type</th>
            <th style={{ width: "10%", textAlign: "center" }}>Required</th>
            <th>Description</th>
          </tr>
        </thead>
        <tbody>
          {Object.keys(schema).map((key) => {
            var property = schema[key];
            return (
              <tr key={key}>
                <td>
                  <strong>{key}</strong>
                </td>
                <td style={{ textAlign: "center", textWrap: "nowrap" }}>
                  {propertyType(property)}
                </td>
                <td style={{ textAlign: "center" }}>
                  {property.nullable ? "No" : "Yes"}
                </td>
                <td
                  dangerouslySetInnerHTML={{
                    __html: property.description,
                  }}
                ></td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  };

  const renderTables = (
    children: React.ReactFragment,
    schema: any,
    schemaName: string
  ): React.ReactFragment => {
    var schemas = new Array<any>();
    if (schema.type) {
      if (schema.type === "array" && schema.items["$ref"]) {
        const schemaName =
          schema.items["$ref"].split("/")[
            schema.items["$ref"].split("/").length - 1
          ];
        var propsa = (swagger.components.schemas as any)[schemaName]
          .properties ?? {
          additionalProp1: {
            type: "string",
            description: "",
          },
          additionalProp2: {
            type: "string",
            description: "",
          },
          additionalProp3: {
            type: "string",
            description: "",
          },
        };
        schema = propsa;
      } else {
        return children;
      }
    }

    Object.keys(schema).forEach((key) => {
      var property = schema[key];
      if (property.type === "array" && property.items["$ref"]) {
        const schemaName =
          property.items["$ref"].split("/")[
            property.items["$ref"].split("/").length - 1
          ];
        var propsb = (swagger.components.schemas as any)[schemaName]
          .properties ?? {
          additionalProp1: {
            type: "string",
            description: "",
          },
          additionalProp2: {
            type: "string",
            description: "",
          },
          additionalProp3: {
            type: "string",
            description: "",
          },
        };
        schemas.push({ properties: propsb, name: schemaName });
      }

      if (property["$ref"]) {
        const schemaName =
          property["$ref"].split("/")[property["$ref"].split("/").length - 1];
        var propsc = (swagger.components.schemas as any)[schemaName]
          .properties ?? {
          additionalProp1: {
            type: "string",
            description: "",
          },
          additionalProp2: {
            type: "string",
            description: "",
          },
          additionalProp3: {
            type: "string",
            description: "",
          },
        };
        schemas.push({ properties: propsc, name: schemaName });
      }
    });

    return (
      <>
        {children}
        <div style={{ marginBottom: 40 }}>
          <h3 id={schemaName}>{schemaName}</h3>
          {renderTable(schema)}
        </div>
        {schemas.map((schema, index) => (
          <div key={index}>
            {renderTables(<></>, schema.properties, schema.name)}
          </div>
        ))}
      </>
    );
  };

  const renderParametersTable = (parameters: any): React.ReactFragment => {
    return (
      <>
        <h3>Path Parameters</h3>
        <table>
          <thead>
            <tr>
              <th style={{ width: "15%" }}>Parameter</th>
              <th style={{ width: "25%", textAlign: "center" }}>Type</th>
              <th style={{ width: "10%", textAlign: "center" }}>Required</th>
              <th>Description</th>
            </tr>
          </thead>
          <tbody>
            {Object.keys(parameters).map((key) => {
              var parameter = parameters[key];
              return (
                <tr key={key}>
                  <td>
                    <strong>{parameter.name}</strong>
                  </td>
                  <td style={{ textAlign: "center", textWrap: "nowrap" }}>
                    {propertyType(parameter.schema)}
                  </td>
                  <td style={{ textAlign: "center" }}>
                    {parameter.nullable ? "No" : "Yes"}
                  </td>
                  <td
                    dangerouslySetInnerHTML={{
                      __html: parameter.description,
                    }}
                  ></td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </>
    );
  };

  return (
    (pathObject && (
      <Stack className="api-reference" tokens={{ childrenGap: 20 }}>
        <StackItem>
          <Stack tokens={{ childrenGap: 10 }}>
            <Text variant="xLarge" block>
              {pathObject.tags[0].replace(/([A-Z])/g, " $1")}
            </Text>
            <Text variant="large" block>
              {pathObject.summary}
            </Text>
            <Text block>
              <span className="http-method">{method?.toUpperCase()}</span>{" "}
              {path}
            </Text>
            <Text block>
              <strong>Host:</strong>{" "}
              {ConfigurationService.Default.Configuration.API?.BaseUri?.replace(
                "/api",
                ""
              )}
            </Text>
          </Stack>
        </StackItem>
        {pathObject.parameters && (
          <StackItem style={{ marginTop: 0, padding: 10 }}>
            {renderParametersTable(pathObject.parameters)}
          </StackItem>
        )}
        <StackItem>
          <Pivot
            selectedKey={currentKey}
            onLinkClick={(item) => setCurrentKey(item!.props.itemKey!)}
          >
            <PivotItem itemKey="request" headerText="Request">
              {(contentType() && (
                <Stack style={{ padding: 10 }} tokens={{ childrenGap: 10 }}>
                  <StackItem>
                    <Text block>
                      <strong>Content-Type:</strong> {contentType()}
                    </Text>
                  </StackItem>
                  {contentType() === "application/json" && (
                    <StackItem>
                      <pre>
                        <code>{exampleValue(contentTypeSchema())}</code>
                      </pre>
                    </StackItem>
                  )}
                  <StackItem>
                    {renderTables(
                      <></>,
                      contentTypeSchema(),
                      contentTypeSchemaName()
                    )}
                  </StackItem>
                </Stack>
              )) || (
                <Stack style={{ padding: 10 }} tokens={{ childrenGap: 10 }}>
                  <StackItem>
                    <Text block>
                      No request body is required for this method.
                    </Text>
                  </StackItem>
                </Stack>
              )}
            </PivotItem>
            <PivotItem itemKey="response" headerText="Response">
              {(responseContentType() && (
                <Stack style={{ padding: 10 }} tokens={{ childrenGap: 10 }}>
                  <StackItem>
                    <Text block>
                      <strong>Content-Type:</strong> {responseContentType()}
                    </Text>
                  </StackItem>
                  {(responseContentType() === "application/json" ||
                    responseContentType() === "application/octet-stream") && (
                    <StackItem>
                      <pre style={{ whiteSpace: "break-spaces" }}>
                        <code>{exampleValue(responseContentTypeSchema())}</code>
                      </pre>
                    </StackItem>
                  )}
                  <StackItem>
                    {renderTables(
                      <></>,
                      responseContentTypeSchema(),
                      responseContentTypeSchemaName()
                    )}
                  </StackItem>
                </Stack>
              )) || (
                <Stack style={{ padding: 10 }} tokens={{ childrenGap: 10 }}>
                  <StackItem>
                    <Text block>This method does not return any data.</Text>
                  </StackItem>
                </Stack>
              )}
            </PivotItem>
          </Pivot>
        </StackItem>
      </Stack>
    )) || <></>
  );
};

export default Method;
