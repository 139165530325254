import { FC, useContext, useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import IChatApp from '../../../../../../../Models/API/IChatApp';
import { chatAppServiceContext } from '../../../../../../../Services/API/ChatAppService';
import IChatAppHierarchy from '../../../../../../../Models/API/IChatAppHierarchy';
import HierarchyNode from './HierarchyNode';

export const transformHierarchyData = (data: IChatAppHierarchy[]): any => {
  const transformNode = (node: IChatAppHierarchy): any => {
    // Create the base node structure
    const chartNode: any = {
      name: node.DisplayName,
      fill: '#0099BC',
      children: [],
    };

    // Add SearchFunctions as children nodes
    for (const searchFunction of node.SearchFunctions) {
      chartNode.children.push({
        name: searchFunction.DisplayName,
        fill: '#0099BC',
        children: [],
      });
    }

    // Add Functions as children nodes
    for (const func of node.Functions) {
      chartNode.children.push({
        name: func.DisplayName,
        fill: '#0099BC',
        children: [],
      });
    }

    // Recursively add ChildApps as children nodes
    for (const childApp of node.ChildApps) {
      chartNode.children.push(transformNode(childApp));
    }

    return chartNode;
  };

  return transformNode(data[0]);
};

export interface IChatAppHierarchyProps {}

const ChatAppHierarchy: FC = () => {
  const chatAppsService = useContext(chatAppServiceContext);
  const { chatApp } = useOutletContext<{ chatApp: IChatApp }>();

  const [hierarchyData, setHierarchyData] = useState<IChatAppHierarchy | null>(null);

  useEffect(() => {
    const execute = async () => {
      let chatAppChats = await chatAppsService!.GetChatAppHierarchy(chatApp.Id);
      if (chatAppChats && chatAppChats.length > 0) {
        setHierarchyData(chatAppChats[0]);
      }
    };
    execute();
  }, [chatApp.Id, chatAppsService]);

  return (
    <div>
      {!hierarchyData && <div>Loading...</div>}
      {hierarchyData && <HierarchyNode node={hierarchyData} />}
    </div>
  );
};

export default ChatAppHierarchy;

// TODO: Delete if not needed
// import { FC, useContext, useEffect, useState } from 'react';
// import { useOutletContext } from 'react-router-dom';
// import IChatApp from '../../../../../../Models/API/IChatApp';
// import { chatAppServiceContext } from '../../../../../../Services/API/ChatAppService';
// import IChatAppHierarchy from '../../../../../../Models/API/IChatAppHierarchy';
// import { TreeChart, DataVizPalette, getColorFromToken } from '@fluentui/react-charting';
// const originalChart = {
//   name: 'Root Node',
//   fill: '#0099BC',
//   children: [
//     {
//       name: 'Child 3',
//       fill: getColorFromToken(DataVizPalette.color3),
//       children: [
//         {
//           name: 'leaf2',
//           subname: 'sub',
//           fill: getColorFromToken(DataVizPalette.color3),
//           children: [
//             {
//               name: 'Child 3',
//               fill: getColorFromToken(DataVizPalette.color3),
//               children: [
//                 {
//                   name: 'leaf2',
//                   subname: 'sub',
//                   fill: getColorFromToken(DataVizPalette.color3),
//                   children: [],
//                 },
//                 {
//                   name: 'leaf2',
//                   fill: getColorFromToken(DataVizPalette.color3),
//                   children: [],
//                 },
//                 {
//                   name: 'leaf3',
//                   subname: 'The subtext is as follows: sub',
//                   fill: getColorFromToken(DataVizPalette.color3),
//                   children: [],
//                 },
//                 {
//                   name: 'leaf4',
//                   subname: 'sub',
//                   fill: getColorFromToken(DataVizPalette.color3),
//                   children: [],
//                 },
//               ],
//             },
//           ],
//         },
//         {
//           name: 'leaf2',
//           fill: getColorFromToken(DataVizPalette.color3),
//           children: [],
//         },
//         {
//           name: 'leaf3',
//           subname: 'The subtext is as follows: sub',
//           fill: getColorFromToken(DataVizPalette.color3),
//           children: [],
//         },
//         {
//           name: 'leaf4',
//           subname: 'sub',
//           fill: getColorFromToken(DataVizPalette.color3),
//           children: [],
//         },
//       ],
//     },
//     {
//       name: 'Child 2 is the child name',
//       fill: getColorFromToken(DataVizPalette.color4),
//       children: [
//         {
//           name: 'leaf5',
//           subname: 'sub',
//           fill: getColorFromToken(DataVizPalette.color4),
//         },
//         {
//           name: 'leaf6',
//           subname: 'sub',
//           fill: getColorFromToken(DataVizPalette.color4),
//         },
//       ],
//     },
//   ],
// };

// export const transformHierarchyData = (data: IChatAppHierarchy[]): any => {
//   const transformNode = (node: IChatAppHierarchy): any => {
//     // Create the base node structure
//     const chartNode: any = {
//       name: node.DisplayName,
//       fill: '#0099BC',
//       children: [],
//     };

//     // Add SearchFunctions as children nodes
//     for (const searchFunction of node.SearchFunctions) {
//       chartNode.children.push({
//         name: searchFunction.DisplayName,
//         fill: getColorFromToken(DataVizPalette.color3),
//         children: [],
//       });
//     }

//     // Add Functions as children nodes
//     for (const func of node.Functions) {
//       chartNode.children.push({
//         name: func.DisplayName,
//         fill: getColorFromToken(DataVizPalette.color2),
//         children: [],
//       });
//     }

//     // Recursively add ChildApps as children nodes
//     for (const childApp of node.ChildApps) {
//       chartNode.children.push(transformNode(childApp));
//     }

//     return chartNode;
//   };

//   return transformNode(data[0]);
// };

// export interface IChatAppHierarchyProps {}

// const ChatAppHierarchy: FC = () => {
//   const chatAppsService = useContext(chatAppServiceContext);
//   const { chatApp } = useOutletContext<{ chatApp: IChatApp }>();

//   const [threeLayerChart, setThreeLayerChart] = useState<any>(undefined);

//   useEffect(() => {
//     const execute = async () => {
//       let chatAppChats = await chatAppsService!.GetChatAppHierarchy(chatApp.Id);
//       if (chatAppChats) {
//         const transformedData = transformHierarchyData(chatAppChats);
//         setThreeLayerChart(transformedData);
//         console.log(transformedData);
//         console.log(JSON.stringify(transformedData));
//         console.log(JSON.stringify(originalChart));
//       }
//     };
//     execute();
//   }, [chatApp.Id, chatAppsService]);

//   return (
//     <div>
//       {!threeLayerChart && <div>Loading...</div>}
//       {threeLayerChart && (
//         <TreeChart
//           treeData={originalChart}
//           margins={{ top: 30, right: 100, bottom: 30, left: 100 }}
//         />
//       )}
//     </div>
//   );
// };

// export default ChatAppHierarchy;
