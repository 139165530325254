import { visit } from "unist-util-visit";
import "katex/dist/katex.min.css";
import Latex from "react-latex-next";

const LatexRemarkPlugin = (options: any) => {
  return (tree: any) => {
    visit(tree, "text", (node) => {
      const latexRegex1 = /\\\[\s*(.*?)\s*\\\]/g;
      const latexRegex2 = /\\\(\s*(.*?)\s*\\\)/g;
      if (latexRegex1.test(node.value) || latexRegex2.test(node.value)) {
        node.type = "html";
        node.value = (
          <Latex
            key={`${node.position.start.line}_${node.position.start.column}`}
          >
            {node.value}
          </Latex>
        );
      }
    });
  };
};

export default LatexRemarkPlugin;
