import { FC, useContext, useEffect, useState } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import IAppCatalog from '../../../../../Models/API/IAppCatalog';
import {
  Stack,
  TextField,
  DefaultButton,
  PrimaryButton,
  Spinner,
  SpinnerSize,
  Slider,
  IComboBoxOption,
  Text,
  Toggle,
  StackItem,
} from '@fluentui/react';
import { INewChatApp, chatAppServiceContext } from '../../../../../Services/API/ChatAppService';
import { trackPageView } from '../../../../../Services/AppInsights';

const defaultNewChatApp: INewChatApp = {
  DisplayName: '',
  Description: '',
  LibraryIds: [],
  SystemPrompt: '',
  NoAnswerMessage: '',
  MaxTokens: 500,
  Temperature: 1,
  PresencePenalty: 0,
  TopP: 1,
  FrequencyPenalty: 0,
  TopResults: 5,
  EnableHybridSearch: true,
  EnableDensitySearch: false,
  EnableModelReRank: false,
  AllowModelSources: false,
  EnableLibraryReferences: false,
  EnableAttachments: false,
  ConversationTimeout: 12,
  Disclaimer: '',
  EnableAnalytics: false,
  HideSources: false,
};

export interface INewChatAppProps {}

export const modelOptions: IComboBoxOption[] = [
  {
    key: 'Gpt3516K',
    text: 'GPT-3.5 Turbo (16K)',
  },
  {
    key: 'Gpt4O',
    text: 'GPT-4o (128K)',
  },
];

const NewChatApp: FC<INewChatAppProps> = () => {
  const navigate = useNavigate();
  const chatAppsService = useContext(chatAppServiceContext);
  const { catalog } = useOutletContext<{ catalog: IAppCatalog }>();

  const [newChatApp, setNewChatApp] = useState<INewChatApp>(defaultNewChatApp);
  const [creatingChatApp, setCreatingChatApp] = useState<boolean>(false);

  // Track page view
  useEffect(() => {
    trackPageView();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const createChatApp = async (): Promise<void> => {
    if (!creatingChatApp) {
      newChatApp.AppCatalogId = catalog.Id;
      setCreatingChatApp(true);
      const newApp = await chatAppsService?.Create(newChatApp);
      if (newApp != null) {
        navigate(`../${newApp!.Id.toString()}`);
      } else {
        setCreatingChatApp(false);
      }
    }
  };

  return (
    <Stack tokens={{ childrenGap: 20 }} verticalFill style={{ minHeight: 0, overflowY: 'auto' }}>
      <Stack.Item>
        <TextField
          required={true}
          label='Display Name'
          value={newChatApp.DisplayName}
          onChange={(event, newValue) =>
            setNewChatApp({
              ...newChatApp,
              DisplayName: newValue,
            })
          }
        />
        <Text variant='xSmall'>Minimum 3 characters</Text>
      </Stack.Item>
      <Stack.Item>
        <TextField
          required={true}
          label='Description'
          multiline
          rows={3}
          resizable={false}
          value={newChatApp.Description}
          onChange={(event, newValue) =>
            setNewChatApp({
              ...newChatApp,
              Description: newValue,
            })
          }
        />
        <Text variant='xSmall'>Minimum 20 characters and maximum 1000 characters</Text>
      </Stack.Item>
      <Stack.Item>
        <TextField
          label='System prompt'
          multiline
          rows={4}
          resizable={false}
          value={newChatApp.SystemPrompt}
          onChange={(event, newValue) =>
            setNewChatApp({
              ...newChatApp,
              SystemPrompt: newValue,
            })
          }
        />
        <Text variant={'xSmall'}>The prompt to be sent to the model to generate a response.</Text>
      </Stack.Item>
      <Stack.Item>
        <TextField
          label='No answer message'
          multiline
          rows={2}
          resizable={true}
          value={newChatApp.NoAnswerMessage}
          onChange={(event, newValue) =>
            setNewChatApp({
              ...newChatApp,
              NoAnswerMessage: newValue!,
            })
          }
        />
        <Text variant={'xSmall'}>
          Message to be sent back to the user when the model is not able to generate a reply (most
          likely due to lack of citations).
        </Text>
      </Stack.Item>
      <Stack wrap horizontal>
        <StackItem>
          <Text variant='large'>Chat Experience</Text>
        </StackItem>
      </Stack>
      <Stack.Item>
        <TextField
          label='Disclaimer'
          multiline
          rows={2}
          resizable={true}
          value={newChatApp.Disclaimer ?? ''}
          onChange={(event, newValue) =>
            setNewChatApp({
              ...newChatApp,
              Disclaimer: newValue!,
            })
          }
        />
        <Text variant={'xSmall'}>
          The first message sent to the user after a conversation is restarted.
        </Text>
      </Stack.Item>
      <Stack wrap horizontal style={{ marginLeft: -10, width: '100%' }}>
        <Stack.Item style={{ maxWidth: '25%', padding: 10, boxSizing: 'border-box' }}>
          <Slider
            label='Conversation Timeout'
            max={24}
            step={1}
            min={1}
            value={newChatApp!.ConversationTimeout}
            showValue
            onChange={val => setNewChatApp({ ...newChatApp, ConversationTimeout: val })}
          />
          <Text variant='xSmall'>
            This field sets the maximum duration (in hours) that a conversation will remain active
            before it restarts. Enter the desired number of hours to ensure conversations
            automatically restart after a specified period of inactivity.
          </Text>
        </Stack.Item>
      </Stack>
      <Stack wrap horizontal style={{ marginLeft: -10 }}>
        <Stack.Item style={{ maxWidth: '25%', padding: 10, boxSizing: 'border-box' }}>
          <Toggle
            label='Enable Library References'
            checked={newChatApp!.EnableLibraryReferences}
            onChange={(evt, checked) =>
              setNewChatApp({
                ...newChatApp,
                EnableLibraryReferences: checked!,
              })
            }
          />
          <Text variant={'xSmall'} block>
            Allows the user to reference files from the application's libraries.
          </Text>
        </Stack.Item>
        <Stack.Item style={{ maxWidth: '25%', padding: 10, boxSizing: 'border-box' }}>
          <Toggle
            label='Enable Attachments'
            checked={newChatApp!.EnableAttachments}
            onChange={(evt, checked) =>
              setNewChatApp({
                ...newChatApp,
                EnableAttachments: checked!,
              })
            }
          />
          <Text variant={'xSmall'} block>
            Allows the user to attach files to the chat.
          </Text>
        </Stack.Item>
      </Stack>
      <Stack wrap horizontal>
        <StackItem>
          <Text variant='large'>Generation</Text>
        </StackItem>
      </Stack>
      <Stack horizontal wrap style={{ marginLeft: -10 }}>
        <Stack.Item style={{ maxWidth: '25%', padding: 10, boxSizing: 'border-box' }}>
          <Slider
            label='Max Tokens'
            max={2000}
            step={100}
            min={0}
            value={newChatApp!.MaxTokens}
            showValue
            onChange={val => setNewChatApp({ ...newChatApp, MaxTokens: val })}
          />
          <Text variant='xSmall'>
            The maximum number of tokens to generate in the completion. The token count of your
            prompt plus max_tokens can't exceed the model's context length. Most models have a
            context length of 2048 tokens (except for the newest models, which support 4096).
          </Text>
        </Stack.Item>
        <Stack.Item style={{ maxWidth: '25%', padding: 10, boxSizing: 'border-box' }}>
          <Slider
            label='Temperature'
            max={2}
            step={0.01}
            min={0}
            value={newChatApp!.Temperature}
            showValue
            onChange={val => setNewChatApp({ ...newChatApp, Temperature: val })}
          />
          <Text variant='xSmall'>
            What sampling temperature to use, between 0 and 2. Higher values means the model will
            take more risks. Try 0.9 for more creative applications, and 0 (argmax sampling) for
            ones with a well-defined answer. We generally recommend altering this or top_p but not
            both.
          </Text>
        </Stack.Item>
        <Stack.Item style={{ maxWidth: '25%', padding: 10, boxSizing: 'border-box' }}>
          <Slider
            label='TopP'
            max={1}
            step={0.01}
            min={0}
            value={newChatApp!.TopP}
            showValue
            onChange={val => setNewChatApp({ ...newChatApp, TopP: val })}
          />
          <Text variant='xSmall'>
            An alternative to sampling with temperature, called nucleus sampling, where the model
            considers the results of the tokens with top_p probability mass. So 0.1 means only the
            tokens comprising the top 10% probability mass are considered. We generally recommend
            altering this or temperature but not both.
          </Text>
        </Stack.Item>
        <Stack.Item style={{ maxWidth: '25%', padding: 10, boxSizing: 'border-box' }}>
          <Slider
            label='Presence Penalty'
            max={2}
            step={0.01}
            min={-2}
            value={newChatApp!.PresencePenalty}
            showValue
            onChange={val => setNewChatApp({ ...newChatApp, PresencePenalty: val })}
          />
          <Text variant='xSmall'>
            Number between -2.0 and 2.0. Positive values penalize new tokens based on whether they
            appear in the text so far, increasing the model's likelihood to talk about new topics.
          </Text>
        </Stack.Item>
        <Stack.Item style={{ maxWidth: '25%', padding: 10, boxSizing: 'border-box' }}>
          <Slider
            label='Frequency Penalty'
            max={2}
            step={0.01}
            min={-2}
            value={newChatApp!.FrequencyPenalty}
            showValue
            onChange={val => setNewChatApp({ ...newChatApp, FrequencyPenalty: val })}
          />
          <Text variant='xSmall'>
            Number between -2.0 and 2.0. Positive values penalize new tokens based on their existing
            frequency in the text so far, decreasing the model's likelihood to repeat the same line
            verbatim.
          </Text>
        </Stack.Item>
      </Stack>
      <Stack wrap horizontal>
        <StackItem>
          <Text variant='large'>Search</Text>
        </StackItem>
      </Stack>
      <Stack grow wrap horizontal style={{ marginLeft: -10 }}>
        <Stack.Item style={{ maxWidth: '25%', padding: 10, boxSizing: 'border-box' }}>
          <Slider
            label='Top Results'
            max={10}
            step={1}
            min={1}
            value={newChatApp!.TopResults}
            showValue
            onChange={val => setNewChatApp({ ...newChatApp, TopResults: val })}
          />
          <Text variant='xSmall'>
            The number of search results to be sent to the model. The higher the number, the more
            context the model has to generate a response.
          </Text>
        </Stack.Item>
        <Stack.Item style={{ maxWidth: '25%', padding: 10, boxSizing: 'border-box' }}>
          <Toggle
            label='Enable Hybrid Search'
            checked={newChatApp!.EnableHybridSearch}
            onChange={(evt, checked) =>
              setNewChatApp({
                ...newChatApp,
                EnableHybridSearch: checked!,
              })
            }
          />
          <Text variant={'xSmall'} block>
            Enables hybrid search which uses vectorial search and keyword search.
          </Text>
        </Stack.Item>
        <Stack.Item style={{ maxWidth: '25%', padding: 10, boxSizing: 'border-box' }}>
          <Toggle
            label='Enable Density Search'
            checked={newChatApp!.EnableDensitySearch}
            onChange={(evt, checked) =>
              setNewChatApp({
                ...newChatApp,
                EnableDensitySearch: checked!,
              })
            }
          />
          <Text variant={'xSmall'} block>
            Enables density search which analyzes the search results and reperforms the search with
            the most relevant documents.
          </Text>
        </Stack.Item>
        <Stack.Item style={{ maxWidth: '25%', padding: 10, boxSizing: 'border-box' }}>
          <Toggle
            label='Enable Model Rerank'
            checked={newChatApp!.EnableModelReRank}
            onChange={(evt, checked) =>
              setNewChatApp({
                ...newChatApp,
                EnableModelReRank: checked!,
              })
            }
          />
          <Text variant={'xSmall'} block>
            Enables model rerank search which uses the model to rerank the search results.
          </Text>
        </Stack.Item>
        <Stack.Item style={{ maxWidth: '25%', padding: 10, boxSizing: 'border-box' }}>
          <Toggle
            label='Allow model sources'
            checked={newChatApp!.AllowModelSources}
            onChange={(evt, checked) =>
              setNewChatApp({
                ...newChatApp,
                AllowModelSources: checked!,
              })
            }
          />
          <Text variant={'xSmall'} block>
            Allows the LLM to generate replies outside of the information of libraries
          </Text>
        </Stack.Item>
        <Stack.Item style={{ maxWidth: '25%', padding: 10, boxSizing: 'border-box' }}>
          <Toggle
            label='Hide Sources'
            checked={newChatApp!.HideSources}
            onChange={(evt, checked) =>
              setNewChatApp({
                ...newChatApp,
                HideSources: checked!,
              })
            }
          />
          <Text variant={'xSmall'} block>
            Hide sources from the chat app answer
          </Text>
        </Stack.Item>
      </Stack>
      <Stack.Item>
        <Stack wrap horizontal style={{ marginTop: '1rem' }}>
          <StackItem>
            <Text variant='large'>Analytics</Text>
          </StackItem>
        </Stack>
        <Stack wrap horizontal style={{ marginLeft: -10 }}>
          <Stack.Item style={{ maxWidth: '25%', padding: 10, boxSizing: 'border-box' }}>
            <Toggle
              label='Enable Analytics'
              checked={newChatApp!.EnableAnalytics}
              onChange={(evt, checked) =>
                setNewChatApp({
                  ...newChatApp,
                  EnableAnalytics: checked!,
                })
              }
            />
            <Text variant={'xSmall'} block>
              Enables analytics for current Chat app.
            </Text>
          </Stack.Item>
        </Stack>
      </Stack.Item>
      <Stack.Item>
        <Stack horizontal tokens={{ childrenGap: 10 }}>
          <DefaultButton onClick={() => navigate(`/catalogseditor/${catalog.Id}`)} text='Cancel' />
          <PrimaryButton
            style={{ minWidth: 80 }}
            onClick={createChatApp}
            disabled={
              !newChatApp.DisplayName ||
              newChatApp.DisplayName.length < 3 ||
              !newChatApp.Description ||
              newChatApp.Description.length < 20 ||
              newChatApp.Description.length > 1000
            }
            text={creatingChatApp ? '' : 'Create'}
          >
            {creatingChatApp && <Spinner size={SpinnerSize.small} />}
          </PrimaryButton>
        </Stack>
      </Stack.Item>
    </Stack>
  );
};

export default NewChatApp;
