import { FC, useContext, useEffect, useState } from "react";
import { analyticsServiceContext } from "../../Services/API/AnalyticsService";
import { IAnalyticsJob } from "../../Models/API/IAnalyticsJob";
import moment from "moment";
import styles from "./AnalyticsJobs.module.scss";
import {
  Spinner,
  SpinnerSize,
  Stack,
  StackItem,
} from "@fluentui/react";

const AnalyticsJobs: FC = () => {
  const analyticsService = useContext(analyticsServiceContext);
  const [jobs, setJobs] = useState<IAnalyticsJob[] | null>(null);

  useEffect(() => {
    loadJobs();
    let intervalId = setInterval(loadJobs, 1000);
    return () => {
      clearInterval(intervalId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadJobs = async () => {
    var jobs = await analyticsService?.GetJobs();
    if (jobs) setJobs(jobs);
  };

  return (
    <Stack tokens={{ childrenGap: 20 }}>
      <StackItem>
        <table className={styles.table}>
          <thead>
            <tr>
              <th>Status</th>
              <th>Started</th>
              <th>Took</th>
              <th>Error Message</th>
            </tr>
          </thead>
          <tbody>
            {jobs &&
              jobs.map((job) => {
                return (
                  <tr key={job.id}>
                    <td>
                      <Stack
                        verticalAlign="center"
                        horizontal
                        tokens={{ childrenGap: 10 }}
                      >
                        <StackItem>{job.status}</StackItem>
                        <StackItem>
                          {job.end_time ? (
                            ""
                          ) : (
                            <Spinner
                              style={{ display: "inline-block" }}
                              size={SpinnerSize.small}
                            />
                          )}
                        </StackItem>
                      </Stack>
                    </td>
                    <td>{moment(job.start_time + "Z").fromNow()}</td>
                    <td>
                      {job.end_time
                        ? moment(job.end_time + "Z").from(
                            moment(job.start_time + "Z"),
                            true
                          )
                        : "Running..."}
                    </td>
                    <td>{job.error ? job.error : "N/A"}</td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </StackItem>
    </Stack>
  );
};

export default AnalyticsJobs;
