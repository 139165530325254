import {
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  FontIcon,
  PrimaryButton,
  Stack,
  Text,
} from "@fluentui/react";
import { FC, createContext, useState } from "react";

type IDialogType = "warning" | "success" | "prompt";

export interface IDialogService {
  showWarningDialog(title: string, message: string): void;
  showSuccessDialog(title: string, message: string): void;
  showPromptDialog(
    title: string,
    message: string,
    onConfirm: () => void,
    onCancel: () => void
  ): void;
}

export const DialogServiceContext = createContext<IDialogService | undefined>(
  undefined
);

const DialogService: FC = ({ children }: any) => {
  const dialogService: IDialogService = {
    showWarningDialog(title: string, message: string) {
      setShowDialog(true);
      setDialogTitle(title);
      setDialogMessage(message);
      setDialogType("warning");
    },
    showSuccessDialog(title: string, message: string) {
      setShowDialog(true);
      setDialogTitle(title);
      setDialogMessage(message);
      setDialogType("success");
    },
    showPromptDialog(
      title: string,
      message: string,
      onConfirm: () => void,
      onCancel: () => void
    ) {
      setShowDialog(true);
      setDialogTitle(title);
      setDialogMessage(message);
      setDialogType("prompt");
      setCallback(() => onConfirm);
      setCancelCallback(() => onCancel);
    },
  };
  const [showDialog, setShowDialog] = useState<boolean>();
  const [dialogType, setDialogType] = useState<IDialogType>();
  const [dialogTitle, setDialogTitle] = useState<string>();
  const [dialogMessage, setDialogMessage] = useState<string>();
  const [callback, setCallback] = useState<() => void>();
  const [cancelCallback, setCancelCallback] = useState<() => void>();

  const confirm = () => {
    setShowDialog(false);
    callback && callback();
  };

  const cancel = () => {
    setShowDialog(false);
    cancelCallback && cancelCallback();
  };

  return (
    <DialogServiceContext.Provider value={dialogService}>
      {children}
      <Dialog
        hidden={!showDialog}
        onDismiss={() => setShowDialog(false)}
        modalProps={{
          isBlocking: true,
          styles: { main: { maxWidth: 450 } },
        }}
        dialogContentProps={{
          showCloseButton: true,
          type: DialogType.normal,
          title: (
            <>
              <Stack
                horizontal
                verticalAlign="center"
                tokens={{ childrenGap: 5 }}
              >
                {dialogType === "warning" && (
                  <FontIcon aria-label="Warning" iconName="Warning" />
                )}
                <Text variant="xLarge">{dialogTitle}</Text>
              </Stack>
            </>
          ),
        }}
      >
        <Text>{dialogMessage}</Text>
        {(dialogType === "prompt" && (
          <DialogFooter>
            <PrimaryButton onClick={confirm} text="Confirm" />
            <DefaultButton onClick={cancel} text="Cancel" />
          </DialogFooter>
        )) || (
          <DialogFooter>
            <PrimaryButton onClick={() => setShowDialog(false)} text="Close" />
          </DialogFooter>
        )}
      </Dialog>
    </DialogServiceContext.Provider>
  );
};

export default DialogService;
