import { FC, useContext, useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import {
  ComboBox,
  DefaultButton,
  IComboBoxStyles,
  PrimaryButton,
  Slider,
  Spinner,
  SpinnerSize,
  Stack,
  StackItem,
  Text,
  TextField,
  Toggle,
} from '@fluentui/react';
import IChatApp from '../../../../../../Models/API/IChatApp';
import { chatAppServiceContext } from '../../../../../../Services/API/ChatAppService';
import styles from './Settings.module.scss';
import { modelOptions } from '../NewChatApp';
import Role from '../../../../../../Components/Role/Role';
import { trackPageView } from '../../../../../../Services/AppInsights';
import { DialogServiceContext } from '../../../../../../Services/Dialogs/DialogService';
import ConfigurationService from '../../../../../../Services/ConfigurationService';
import AuthenticationService from '../../../../../../Services/AuthenticationService';
import { analyticsServiceContext } from '../../../../../../Services/API/AnalyticsService';

enum Action {
  None,
  StartAnalytics,
  PurgeAll,
}

const comboBoxStyles: Partial<IComboBoxStyles> = {
  root: { maxWidth: 300 },
  optionsContainer: { width: 300 },
  label: { overflow: 'visible' },
};

const ChatAppSettings: FC = () => {
  const dialogService = useContext(DialogServiceContext);
  const chatAppService = useContext(chatAppServiceContext);
  const analyticsService = useContext(analyticsServiceContext);

  const { chatApp, setChatApp } = useOutletContext<{
    chatApp: IChatApp;
    setChatApp: (value: IChatApp) => void;
  }>();

  const [tempChatApp, setTempChatApp] = useState<IChatApp>(chatApp);
  const [saving, setIsSaving] = useState<boolean>(false);

  const [action, setAction] = useState<Action>(Action.None);
  const [executingJob, setExecutingJob] = useState<boolean>(false);

  // Track page view
  useEffect(() => {
    document.title = `${ConfigurationService.Default.Configuration.PageTitle} - ${chatApp.DisplayName} Settings`;
    trackPageView();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateChatApp = async () => {
    if (tempChatApp && !saving) {
      setIsSaving(true);
      var refreshedChatApp = await chatAppService?.Update(tempChatApp!.Id, {
        Model: tempChatApp.Model,
        DisplayName: tempChatApp.DisplayName,
        Description: tempChatApp.Description,
        SystemPrompt: tempChatApp.SystemPrompt,
        NoAnswerMessage: tempChatApp.NoAnswerMessage,
        MaxTokens: tempChatApp.MaxTokens,
        Temperature: tempChatApp.Temperature,
        PresencePenalty: tempChatApp.PresencePenalty,
        TopP: tempChatApp.TopP,
        FrequencyPenalty: tempChatApp.FrequencyPenalty,
        LibraryIds: tempChatApp.LibraryIds,
        AllowModelSources: tempChatApp.AllowModelSources,
        TopResults: tempChatApp.TopResults,
        EnableHybridSearch: tempChatApp.EnableHybridSearch,
        EnableDensitySearch: tempChatApp.EnableDensitySearch,
        EnableModelReRank: tempChatApp.EnableModelReRank,
        EnableLibraryReferences: tempChatApp.EnableLibraryReferences,
        EnableAttachments: tempChatApp.EnableAttachments,
        ConversationTimeout: tempChatApp.ConversationTimeout,
        Disclaimer: tempChatApp.Disclaimer,
        EnableAnalytics: tempChatApp.EnableAnalytics,
        HideSources: tempChatApp.HideSources,
      });
      if (refreshedChatApp !== null) {
        setChatApp(refreshedChatApp!);
        setTempChatApp(refreshedChatApp!);
        dialogService?.showSuccessDialog(
          refreshedChatApp!.DisplayName,
          'All settings have been saved.'
        );
      }
      setIsSaving(false);
    }
  };

  const maxTopResultsByModel = (model: string): number => {
    return model === 'Gpt4' ? 5 : model === 'Gpt3516K' ? 10 : model === 'Gpt432K' ? 15 : 20;
  };

  const startAnalytics = async () => {
    setExecutingJob(true);
    await analyticsService?.StartChatAppAnalytics(chatApp.Id);
    setExecutingJob(false);
  };

  const purgeAll = async () => {
    setExecutingJob(true);
    await analyticsService?.PurgeAllChatAppConversations(chatApp.Id);
    setExecutingJob(false);
  };

  const executeAction = async (action: Action) => {
    switch (action) {
      case Action.StartAnalytics:
        await startAnalytics();
        break;
      case Action.PurgeAll:
        await purgeAll();
        break;
    }
    setAction(Action.None);
  };

  useEffect(() => {
    if (action !== Action.None) {
      switch (action) {
        case Action.StartAnalytics:
          dialogService?.showPromptDialog(
            'Start Analytics',
            'Are you sure you want to start analytics?',
            () => {
              executeAction(action);
            },
            () => {
              setAction(Action.None);
            }
          );
          break;
        case Action.PurgeAll:
          dialogService?.showPromptDialog(
            'Purge All',
            'Are you sure you want to purge all data?',
            () => {
              dialogService?.showPromptDialog(
                'Purge All',
                'Are you really sure you want to purge all data? This action cannot be undone.',
                () => {
                  executeAction(action);
                },
                () => {
                  setAction(Action.None);
                }
              );
            },
            () => {
              setAction(Action.None);
            }
          );
          break;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [action]);

  return (
    (tempChatApp && (
      <>
        <Stack
          verticalFill
          style={{ minHeight: 0, overflowY: 'auto' }}
          tokens={{ childrenGap: 20 }}
        >
          <Stack.Item>
            <TextField
              required={true}
              label='Title'
              resizable={false}
              value={tempChatApp.DisplayName}
              onChange={(event, newValue) =>
                setTempChatApp({
                  ...tempChatApp,
                  DisplayName: newValue!,
                })
              }
            />
            <Text variant='xSmall'>Minimum 3 characters</Text>
          </Stack.Item>
          <Stack.Item>
            <TextField
              required={true}
              label='Description'
              multiline
              rows={3}
              resizable={false}
              value={tempChatApp.Description}
              onChange={(event, newValue) =>
                setTempChatApp({
                  ...tempChatApp,
                  Description: newValue!,
                })
              }
            />
            <Text variant='xSmall'>Minimum 20 characters</Text>
          </Stack.Item>
          <Stack.Item>
            <TextField
              label='System prompt'
              multiline
              rows={4}
              resizable={true}
              value={tempChatApp.SystemPrompt}
              onChange={(event, newValue) =>
                setTempChatApp({
                  ...tempChatApp,
                  SystemPrompt: newValue!,
                })
              }
            />
            <Text variant={'xSmall'}>
              The prompt to be sent to the model to generate a response.
            </Text>
          </Stack.Item>
          <Stack.Item>
            <TextField
              label='No answer message'
              multiline
              rows={2}
              resizable={true}
              value={tempChatApp.NoAnswerMessage}
              onChange={(event, newValue) =>
                setTempChatApp({
                  ...tempChatApp,
                  NoAnswerMessage: newValue!,
                })
              }
            />
            <Text variant={'xSmall'}>
              Message to be sent back to the user when the model is not able to generate a reply
              (most likely due to lack of citations).
            </Text>
          </Stack.Item>
          <Stack wrap horizontal>
            <StackItem>
              <Text variant='large'>Chat Experience</Text>
            </StackItem>
          </Stack>
          <Stack.Item>
            <TextField
              label='Disclaimer'
              multiline
              rows={2}
              resizable={true}
              value={tempChatApp.Disclaimer ?? ''}
              onChange={(event, newValue) =>
                setTempChatApp({
                  ...tempChatApp,
                  Disclaimer: newValue!,
                })
              }
            />
            <Text variant={'xSmall'}>
              The first message sent to the user after a conversation is restarted.
            </Text>
          </Stack.Item>
          <Stack wrap horizontal style={{ marginLeft: -10, width: '100%' }}>
            <Stack.Item style={{ maxWidth: '25%', padding: 10, boxSizing: 'border-box' }}>
              <Slider
                label='Conversation Timeout'
                max={24}
                step={1}
                min={1}
                value={tempChatApp!.ConversationTimeout}
                showValue
                onChange={val => setTempChatApp({ ...tempChatApp, ConversationTimeout: val })}
              />
              <Text variant='xSmall'>
                This field sets the maximum duration (in hours) that a conversation will remain
                active before it restarts. Enter the desired number of hours to ensure conversations
                automatically restart after a specified period of inactivity.
              </Text>
            </Stack.Item>
          </Stack>
          <Stack wrap horizontal style={{ marginLeft: -10 }}>
            <Stack.Item style={{ maxWidth: '25%', padding: 10, boxSizing: 'border-box' }}>
              <Toggle
                label='Enable Library References'
                checked={tempChatApp!.EnableLibraryReferences}
                onChange={(evt, checked) =>
                  setTempChatApp({
                    ...tempChatApp,
                    EnableLibraryReferences: checked!,
                  })
                }
              />
              <Text variant={'xSmall'} block>
                Allows the user to reference files from the application's libraries.
              </Text>
            </Stack.Item>
            <Stack.Item style={{ maxWidth: '25%', padding: 10, boxSizing: 'border-box' }}>
              <Toggle
                label='Enable Attachments'
                checked={tempChatApp!.EnableAttachments}
                onChange={(evt, checked) =>
                  setTempChatApp({
                    ...tempChatApp,
                    EnableAttachments: checked!,
                  })
                }
              />
              <Text variant={'xSmall'} block>
                Allows the user to attach files to the chat.
              </Text>
            </Stack.Item>
          </Stack>
          <Stack wrap horizontal>
            <StackItem>
              <Text variant='large'>Generation</Text>
            </StackItem>
          </Stack>
          <Stack wrap horizontal style={{ marginLeft: -10 }}>
            <Stack.Item style={{ maxWidth: '25%', padding: 10, boxSizing: 'border-box' }}>
              <Slider
                label='Max Tokens'
                max={2000}
                step={100}
                min={0}
                value={tempChatApp!.MaxTokens}
                showValue
                onChange={val => setTempChatApp({ ...tempChatApp, MaxTokens: val })}
              />
              <Text variant='xSmall'>
                The maximum number of tokens to generate in the completion. The token count of your
                prompt plus max_tokens can't exceed the model's context length. Most models have a
                context length of 2048 tokens (except for the newest models, which support 4096).
              </Text>
            </Stack.Item>
            <Stack.Item style={{ maxWidth: '25%', padding: 10, boxSizing: 'border-box' }}>
              <Slider
                label='Temperature'
                max={2}
                step={0.01}
                min={0}
                value={tempChatApp!.Temperature}
                showValue
                onChange={val => setTempChatApp({ ...tempChatApp, Temperature: val })}
              />
              <Text variant='xSmall'>
                What sampling temperature to use, between 0 and 2. Higher values means the model
                will take more risks. Try 0.9 for more creative applications, and 0 (argmax
                sampling) for ones with a well-defined answer. We generally recommend altering this
                or top_p but not both.
              </Text>
            </Stack.Item>
            <Stack.Item style={{ maxWidth: '25%', padding: 10, boxSizing: 'border-box' }}>
              <Slider
                label='TopP'
                max={1}
                step={0.01}
                min={0}
                value={tempChatApp!.TopP}
                showValue
                onChange={val => setTempChatApp({ ...tempChatApp, TopP: val })}
              />
              <Text variant='xSmall'>
                An alternative to sampling with temperature, called nucleus sampling, where the
                model considers the results of the tokens with top_p probability mass. So 0.1 means
                only the tokens comprising the top 10% probability mass are considered. We generally
                recommend altering this or temperature but not both.
              </Text>
            </Stack.Item>
            <Stack.Item style={{ maxWidth: '25%', padding: 10, boxSizing: 'border-box' }}>
              <Slider
                label='Presence Penalty'
                max={2}
                step={0.01}
                min={-2}
                value={tempChatApp!.PresencePenalty}
                showValue
                onChange={val => setTempChatApp({ ...tempChatApp, PresencePenalty: val })}
              />
              <Text variant='xSmall'>
                Number between -2.0 and 2.0. Positive values penalize new tokens based on whether
                they appear in the text so far, increasing the model's likelihood to talk about new
                topics.
              </Text>
            </Stack.Item>
            <Stack.Item style={{ maxWidth: '25%', padding: 10, boxSizing: 'border-box' }}>
              <Slider
                label='Frequency Penalty'
                max={2}
                step={0.01}
                min={-2}
                value={tempChatApp!.FrequencyPenalty}
                showValue
                onChange={val => setTempChatApp({ ...tempChatApp, FrequencyPenalty: val })}
              />
              <Text variant='xSmall'>
                Number between -2.0 and 2.0. Positive values penalize new tokens based on their
                existing frequency in the text so far, decreasing the model's likelihood to repeat
                the same line verbatim.
              </Text>
            </Stack.Item>
          </Stack>
          <Stack wrap horizontal>
            <StackItem>
              <Text variant='large'>Search</Text>
            </StackItem>
          </Stack>
          <Stack wrap horizontal style={{ marginLeft: -10 }}>
            <Stack.Item style={{ maxWidth: '25%', padding: 10, boxSizing: 'border-box' }}>
              <Slider
                label='Top Results'
                max={maxTopResultsByModel(tempChatApp.Model)}
                step={1}
                min={1}
                value={tempChatApp!.TopResults}
                showValue
                onChange={val => {
                  setTempChatApp({ ...tempChatApp, TopResults: val });
                }}
              />
              <Text variant='xSmall'>
                The number of search results to be sent to the model. The higher the number, the
                more context the model has to generate a response.
              </Text>
            </Stack.Item>
            <Stack.Item style={{ maxWidth: '25%', padding: 10, boxSizing: 'border-box' }}>
              <Toggle
                label='Enable Hybrid Search'
                checked={tempChatApp!.EnableHybridSearch}
                onChange={(evt, checked) =>
                  setTempChatApp({
                    ...tempChatApp,
                    EnableHybridSearch: checked!,
                  })
                }
              />
              <Text variant={'xSmall'} block>
                Enables hybrid search which uses vectorial search and keyword search.
              </Text>
            </Stack.Item>
            <Stack.Item style={{ maxWidth: '25%', padding: 10, boxSizing: 'border-box' }}>
              <Toggle
                label='Enable Density Search'
                checked={tempChatApp!.EnableDensitySearch}
                onChange={(evt, checked) =>
                  setTempChatApp({
                    ...tempChatApp,
                    EnableDensitySearch: checked!,
                  })
                }
              />
              <Text variant={'xSmall'} block>
                Enables density search which analyzes the search results and reperforms the search
                with the most relevant documents.
              </Text>
            </Stack.Item>
            <Stack.Item style={{ maxWidth: '25%', padding: 10, boxSizing: 'border-box' }}>
              <Toggle
                label='Enable Model Rerank'
                checked={tempChatApp!.EnableModelReRank}
                onChange={(evt, checked) =>
                  setTempChatApp({
                    ...tempChatApp,
                    EnableModelReRank: checked!,
                  })
                }
              />
              <Text variant={'xSmall'} block>
                Enables model rerank search which uses the model to rerank the search results.
              </Text>
            </Stack.Item>
            <Stack.Item style={{ maxWidth: '25%', padding: 10, boxSizing: 'border-box' }}>
              <Toggle
                label='Allow model sources'
                checked={tempChatApp!.AllowModelSources}
                onChange={(evt, checked) =>
                  setTempChatApp({
                    ...tempChatApp,
                    AllowModelSources: checked!,
                  })
                }
              />
              <Text variant={'xSmall'} block>
                Allows the LLM to generate replies outside of the information of libraries
              </Text>
            </Stack.Item>
            <Stack.Item style={{ maxWidth: '25%', padding: 10, boxSizing: 'border-box' }}>
              <Toggle
                label='Hide Sources'
                checked={tempChatApp!.HideSources}
                onChange={(evt, checked) =>
                  setTempChatApp({
                    ...tempChatApp,
                    HideSources: checked!,
                  })
                }
              />
              <Text variant={'xSmall'} block>
                Hide sources from the chat app answer
              </Text>
            </Stack.Item>
          </Stack>
          <Stack.Item>
            <Role hide allowedRoles={['admin']}>
              <Stack grow horizontal tokens={{ childrenGap: 10 }}>
                <Stack.Item grow>
                  <ComboBox
                    style={{ minWidth: 200 }}
                    label='Model'
                    options={modelOptions}
                    selectedKey={tempChatApp.Model}
                    onChange={(evt, option) => {
                      setTempChatApp({
                        ...tempChatApp,
                        Model: option!.key as any,
                        TopResults: Math.min(
                          maxTopResultsByModel(option!.key as string),
                          tempChatApp.TopResults
                        ),
                      });
                    }}
                    styles={comboBoxStyles}
                  />
                </Stack.Item>
              </Stack>
            </Role>
            {!AuthenticationService.Default.HasRole('admin') && (
              <Stack.Item>
                <Text>
                  <strong>Model:</strong>{' '}
                  {modelOptions.filter(o => o.key === chatApp.Model)[0].text}
                  <Text variant={'xSmall'} block>
                    If you require a model change, please contact an administrator.
                  </Text>
                </Text>
              </Stack.Item>
            )}
            <Stack wrap horizontal style={{ marginTop: '2rem' }}>
              <StackItem>
                <Text variant='large'>Analytics</Text>
              </StackItem>
            </Stack>
            <Stack
              verticalAlign='center'
              style={{ marginLeft: -10, marginBottom: 20 }}
              tokens={{ childrenGap: 20 }}
            >
              <Stack.Item style={{ maxWidth: '25%', padding: 10, boxSizing: 'border-box' }}>
                <Toggle
                  label='Enable Analytics'
                  checked={tempChatApp!.EnableAnalytics}
                  onChange={(evt, checked) =>
                    setTempChatApp({
                      ...tempChatApp,
                      EnableAnalytics: checked!,
                    })
                  }
                />
                <Text variant={'xSmall'} block>
                  Enables analytics for current Chat app.
                </Text>
              </Stack.Item>
              {AuthenticationService.Default.HasRole('admin') && (
                <>
                  {chatApp.EnableAnalytics && (
                    <>
                      <Stack.Item>
                        <DefaultButton
                          style={{ marginLeft: 15, marginRight: 10 }}
                          onClick={() => setAction(Action.StartAnalytics)}
                          text={
                            executingJob ? 'Executing...' : `Start ${chatApp.DisplayName} Analytics`
                          }
                          allowDisabledFocus
                        />
                        <Text variant={'xSmall'}>
                          Starts the analytics timer job on demand for the current Chat App.
                        </Text>
                      </Stack.Item>
                      <Stack.Item>
                        <DefaultButton
                          style={{ marginLeft: 15, marginRight: 10 }}
                          onClick={() => setAction(Action.PurgeAll)}
                          text={
                            executingJob ? 'Executing...' : `Purge ${chatApp.DisplayName} Analytics`
                          }
                          allowDisabledFocus
                        />
                        <Text variant={'xSmall'}>
                          Purges all data from analytics from the current Chat App.
                        </Text>
                      </Stack.Item>
                    </>
                  )}
                </>
              )}
            </Stack>
          </Stack.Item>
          <Stack.Item>
            <section className={styles.buttonSection}>
              <PrimaryButton
                style={{ minWidth: 80 }}
                onClick={updateChatApp}
                disabled={
                  !tempChatApp.DisplayName ||
                  tempChatApp.DisplayName.length < 3 ||
                  !tempChatApp.Description ||
                  tempChatApp.Description.length < 20 ||
                  tempChatApp.Description.length > 1000
                }
                text={saving ? '' : 'Save'}
              >
                {saving && <Spinner hidden={!saving} size={SpinnerSize.small} />}
              </PrimaryButton>
            </section>
          </Stack.Item>
        </Stack>
      </>
    )) || (
      <Stack
        grow
        verticalFill
        style={{ minHeight: 0 }}
        horizontalAlign='center'
        className='main-content'
      >
        <Stack grow verticalAlign='center'>
          <Spinner size={SpinnerSize.large} />
        </Stack>
      </Stack>
    )
  );
};

export default ChatAppSettings;
