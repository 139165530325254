import "./Navigation.scss";
import { Link, NavLink } from "react-router-dom";
import { Link as FluentLink, Stack, Text } from "@fluentui/react";
import Role from "../Role/Role";
import { FC, useContext, useEffect, useState } from "react";
import AuthenticationService from "../../Services/AuthenticationService";
import { UserServiceContext } from "../../Services/API/UserService";
import IUserInformation from "../../Models/API/IUserInformation";
import DarkModeSwitch from "../DarkModeSwitch/DarkModeSwitch";
import { AppCatalogServiceContext } from "../../Services/AppCatalogService";

const Navigation: FC = () => {
  const appCatalogService = useContext(AppCatalogServiceContext);
  const userService = useContext(UserServiceContext);
  const [user, setUser] = useState<IUserInformation>();

  useEffect(() => {
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const init = async () => {
    const user = await userService?.GetUserInformation();
    setUser(user!);
  };

  return (
    <Stack
      horizontal
      className="header"
      tokens={{ padding: 16, childrenGap: 20 }}
      grow
      verticalAlign="center"
    >
      <Stack.Item shrink>
        <Link to="/">
          <img src={appCatalogService?.logo} alt="" />
        </Link>
      </Stack.Item>
      <Stack horizontal className="nav" grow tokens={{ childrenGap: 0 }}>
        <Role
          hide={true}
          children={
            <Stack.Item>
              <NavLink
                className={({ isActive }) => (isActive ? "active" : "")}
                to="/chat"
              >
                <FluentLink>ChatJTI</FluentLink>
              </NavLink>
            </Stack.Item>
          }
        />
        <Role
          hide={true}
          children={
            <Stack.Item>
              <NavLink
                className={({ isActive }) => (isActive ? "active" : "")}
                to="/catalogs"
              >
                <FluentLink>Catalogs</FluentLink>
              </NavLink>
            </Stack.Item>
          }
        />
        {user?.CanEdit && (
          <Role
            hide={true}
            children={
              <Stack.Item>
                <NavLink
                  className={({ isActive }) =>
                    isActive ? "active hideInMobile" : "hideInMobile"
                  }
                  to="/catalogseditor"
                >
                  <FluentLink>Catalogs Editor</FluentLink>
                </NavLink>
              </Stack.Item>
            }
          />
        )}
        <Role
          allowedRoles={["admin"]}
          hide={true}
          children={
            <Stack.Item>
              <NavLink
                className={({ isActive }) =>
                  isActive ? "active hideInMobile" : "hideInMobile"
                }
                to="/settings"
              >
                <FluentLink>Settings</FluentLink>
              </NavLink>
            </Stack.Item>
          }
        />
      </Stack>
      <Stack className="hideInMobile">
        <Stack.Item>
          <DarkModeSwitch />
        </Stack.Item>
      </Stack>
      <Stack style={{ textAlign: "right" }} className="hideInMobile">
        <Stack.Item>
          <Text block variant="medium">
            <FluentLink
              onClick={(evt) => AuthenticationService.Default.Logout()}
            >
              Logout
            </FluentLink>
          </Text>
        </Stack.Item>
      </Stack>
    </Stack>
  );
};

export { Navigation };
