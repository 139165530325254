import {
  ChangeEvent,
  FC,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useOutletContext } from "react-router-dom";
import {
  IIconProps,
  IconButton,
  Label,
  PrimaryButton,
  Spinner,
  SpinnerSize,
  Stack,
  StackItem,
  Text,
  TextField,
} from "@fluentui/react";

import { catalogServiceContext } from "../../../../Services/API/CatalogService";
import IAppCatalog from "../../../../Models/API/IAppCatalog";
import { AxiosError } from "axios";
import { DialogServiceContext } from "../../../../Services/Dialogs/DialogService";
import styles from "./Settings.module.scss";
import AppColorPicker from "../../../../Components/ColorPicker/AppColorPicker";
import { trackPageView } from "../../../../Services/AppInsights";
import ConfigurationService from "../../../../Services/ConfigurationService";

const VALID_UPLOAD_FILE_EXTENSIONS = ["jpg", "jpeg", "png", "gif"];

const refreshIcon: IIconProps = { iconName: "Refresh" };

const CatalogSettings: FC = () => {
  const catalogService = useContext(catalogServiceContext);
  const dialogService = useContext(DialogServiceContext);

  const { catalog, setCatalog } = useOutletContext<{
    catalog: IAppCatalog;
    setCatalog: (value: IAppCatalog) => void;
  }>();
  const [tempCatalog, setTempCatalog] = useState<IAppCatalog>(catalog);
  const [saving, setIsSaving] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const [creatingCatalog] = useState<boolean>(false);
  const [files, setFiles] = useState<FileList | null>();
  const [showUploadMessage, setShowUploadMessage] = useState<boolean>(false);
  const [originalLogo, setOriginalLogo] = useState<string | null>(null);
  const [imageData, setImageData] = useState<string | null>(null);
  const [loadingLogo, setLoadingLogo] = useState<boolean>(false);

  // Track page view
  useEffect(() => {
    document.title = `${ConfigurationService.Default.Configuration.PageTitle} - ${catalog.DisplayName} Settings`;
    trackPageView();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateCatalog = async () => {
    if (tempCatalog && !saving) {
      setIsSaving(true);
      var refreshedCatalog = await catalogService?.Update(tempCatalog!.Id, {
        DisplayName: tempCatalog.DisplayName,
        Description: tempCatalog.Description,
        BackgroundColor: tempCatalog.BackgroundColor,
        PrimaryColor: tempCatalog.PrimaryColor,
        SecondaryColor: tempCatalog.SecondaryColor,
      });
      if (refreshedCatalog) {
        setCatalog(refreshedCatalog);
        if (files) {
          await uploadLogo(refreshedCatalog.Id);
        }
      }
      setIsSaving(false);
    }
  };

  const resetLogo = () => {
    setFiles(null);
    setShowUploadMessage(false);
    setImageData(originalLogo);
  };

  const onFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
      var file = files[0];

      if (file) {
        const extension = file.name.split(".")[file.name.split(".").length - 1];
        if (
          VALID_UPLOAD_FILE_EXTENSIONS.indexOf(extension.toLowerCase()) === -1
        ) {
          dialogService?.showWarningDialog(
            "Logo Upload",
            "File extension not supported."
          );
        } else {
          setFiles(files);
          setImageData(URL.createObjectURL(file));
          setShowUploadMessage(true);
        }
      }
    }
  };

  const uploadLogo = async (catalogId: number) => {
    if (files) {
      var file = files[0];

      if (file) {
        const extension = file.name.split(".")[file.name.split(".").length - 1];
        if (
          VALID_UPLOAD_FILE_EXTENSIONS.indexOf(extension.toLowerCase()) === -1
        ) {
          dialogService?.showWarningDialog(
            "Logo Upload",
            "File extension not supported."
          );
        } else {
          try {
            await catalogService!.UpdateLogo(catalog.Id, file);
            setShowUploadMessage(false);
          } catch (error) {
            const axiosError = error as AxiosError;
            if (
              axiosError.response?.status === 409 ||
              axiosError.response?.status === 422
            ) {
              dialogService?.showWarningDialog(
                "Logo Upload",
                (axiosError.response.data as any).details
              );
            } else if (axiosError.response?.status === 400) {
              const errorMsg = (axiosError.response.data as any).errors[""][0];
              if (
                errorMsg.toLowerCase().indexOf("request body too large") !== -1
              ) {
                dialogService?.showWarningDialog(
                  "Logo Upload",
                  "The provided file exceeds the allowable size limit. Please ensure that the file size is below 100 megabytes (MB) and try again."
                );
              } else {
                dialogService?.showWarningDialog("Document Upload", errorMsg);
              }
            }
          }
        }
      }
    }
  };

  const getCatalogLogo = async () => {
    setLoadingLogo(true);
    if (catalog.LogoUrl) {
      const binaryData = await catalogService?.GetLogo(catalog.Id);
      if (binaryData) {
        const data = `data:image/png;base64, ${binaryData}`;
        setOriginalLogo(data);
        setImageData(data);
      }
    }
    setLoadingLogo(false);
  };

  useEffect(() => {
    const execute = async () => {
      await getCatalogLogo();
    };

    execute();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    (tempCatalog && (
      <Stack verticalFill style={{ minHeight: 0, overflowY: "auto" }}>
        <section className={styles.gridContainer}>
          <Stack tokens={{ childrenGap: 20 }}>
            <Stack.Item>
              <TextField
                required={true}
                label="Title"
                resizable={false}
                value={tempCatalog.DisplayName}
                onChange={(event, newValue) =>
                  setTempCatalog({
                    ...tempCatalog,
                    DisplayName: newValue!,
                  })
                }
              />
              <Text variant="xSmall">Minimum 3 characters</Text>
            </Stack.Item>
            <Stack.Item>
              <TextField
                required={true}
                label="Description"
                multiline
                rows={3}
                resizable={false}
                value={tempCatalog.Description}
                onChange={(event, newValue) =>
                  setTempCatalog({
                    ...tempCatalog,
                    Description: newValue!,
                  })
                }
              />
              <Text variant="xSmall">
                Minimum 20 characters and maximum 1000 characters
              </Text>
            </Stack.Item>
            <Stack.Item>
              <Stack tokens={{ childrenGap: 20 }}>
                <StackItem>
                  <Label>Background color</Label>
                  <Stack
                    horizontal
                    verticalAlign="end"
                    tokens={{ childrenGap: 10 }}
                  >
                    <TextField
                      style={{ width: 80 }}
                      value={tempCatalog.BackgroundColor}
                      onChange={(event, newValue) =>
                        setTempCatalog({
                          ...tempCatalog,
                          BackgroundColor: newValue!,
                        })
                      }
                    />
                    <AppColorPicker
                      color={tempCatalog.BackgroundColor}
                      showPreview
                      setColor={(newValue) =>
                        setTempCatalog({
                          ...tempCatalog,
                          BackgroundColor: newValue!,
                        })
                      }
                    />
                  </Stack>
                </StackItem>
                <StackItem>
                  <Label>Primary color</Label>
                  <Stack
                    horizontal
                    verticalAlign="end"
                    tokens={{ childrenGap: 10 }}
                  >
                    <TextField
                      style={{ width: 80 }}
                      value={tempCatalog.PrimaryColor}
                      onChange={(event, newValue) =>
                        setTempCatalog({
                          ...tempCatalog,
                          PrimaryColor: newValue!,
                        })
                      }
                    />
                    <AppColorPicker
                      color={tempCatalog.PrimaryColor}
                      showPreview
                      setColor={(newValue) =>
                        setTempCatalog({
                          ...tempCatalog,
                          PrimaryColor: newValue!,
                        })
                      }
                    />
                  </Stack>
                </StackItem>
                <StackItem>
                  <Label>Text color</Label>
                  <Stack
                    horizontal
                    verticalAlign="end"
                    tokens={{ childrenGap: 10 }}
                  >
                    <TextField
                      style={{ width: 80 }}
                      value={tempCatalog.SecondaryColor}
                      onChange={(event, newValue) =>
                        setTempCatalog({
                          ...tempCatalog,
                          SecondaryColor: newValue!,
                        })
                      }
                    />
                    <AppColorPicker
                      color={tempCatalog.SecondaryColor}
                      showPreview
                      setColor={(newValue) =>
                        setTempCatalog({
                          ...tempCatalog,
                          SecondaryColor: newValue!,
                        })
                      }
                    />
                  </Stack>
                </StackItem>
              </Stack>
            </Stack.Item>
          </Stack>

          <Stack className={styles.logoSection}>
            <section>
              {loadingLogo ? (
                <Spinner size={SpinnerSize.large} />
              ) : imageData ? (
                <img src={imageData} alt="some logo" />
              ) : (
                <>
                  <p>No logo</p>
                </>
              )}
            </section>
            <Stack.Item>
              <footer className={styles.logoSectionFooter}>
                <input
                  ref={inputRef}
                  type="file"
                  accept={VALID_UPLOAD_FILE_EXTENSIONS.map(
                    (ext) => `.${ext}`
                  ).join(",")}
                  hidden={true}
                  onChange={onFileChange}
                />
                <PrimaryButton
                  style={{ minWidth: 80 }}
                  onClick={() => inputRef.current?.click()}
                  text={"Upload logo"}
                >
                  {creatingCatalog && <Spinner size={SpinnerSize.small} />}
                </PrimaryButton>
                {showUploadMessage && (
                  <div>
                    <IconButton
                      iconProps={refreshIcon}
                      title="Emoji"
                      ariaLabel="Emoji"
                      onClick={resetLogo}
                    />

                    <p>New logo will only be uploaded on save</p>
                  </div>
                )}
              </footer>
            </Stack.Item>
          </Stack>

          <Stack horizontal tokens={{ childrenGap: 10 }}>
            <PrimaryButton
              style={{ minWidth: 80 }}
              onClick={updateCatalog}
              disabled={
                !tempCatalog.DisplayName ||
                tempCatalog.DisplayName.length < 3 ||
                !tempCatalog.Description ||
                tempCatalog.Description.length < 20 ||
                tempCatalog.Description.length > 1000
              }
              text={saving ? "" : "Save"}
            >
              {saving && <Spinner hidden={!saving} size={SpinnerSize.small} />}
            </PrimaryButton>
          </Stack>
        </section>
      </Stack>
    )) || <Spinner size={SpinnerSize.large} />
  );
};

export default CatalogSettings;
