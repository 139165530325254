import { FC, createContext, useContext } from "react";
import { ApiHttpServiceContext } from "./ApiHttpService";
import ILibrary, { ILibraryMetadata } from "../../Models/API/ILibrary";
import { CacheServiceContext } from "./CacheService";
import ILibraryPermission from "../../Models/API/ILibraryPermission";
import IMetadataField, {
  IUpdateMetadataField,
} from "../../Models/API/IMetadataField";
import IUserPermission, {
  PermissionType,
} from "../../Models/API/IUserPermission";

export interface INewLibrary {
  Title?: string;
  Description?: string;  
  Metadata?: ILibraryMetadata;
}

export interface ILibraryService {
  GetAll(): Promise<ILibrary[] | null>;
  Get(libraryId: number): Promise<ILibrary | null>;
  Delete(libraryId: number): Promise<void | null>;
  Create(library: INewLibrary): Promise<ILibrary | null>;
  Update(libraryId: number, library: INewLibrary): Promise<ILibrary | null>;
  GetPermissions(libraryId: number): Promise<ILibraryPermission[] | null>;
  CreatePermission(
    libraryId: number,
    principalId: string,
    type: PermissionType
  ): Promise<ILibraryPermission | null>;
  UpdatePermission(
    libraryId: number,
    principalId: string,
    type: PermissionType
  ): Promise<ILibraryPermission | null>;
  CheckUserPermissions(
    principalId: string,
    libraryId: number
  ): Promise<IUserPermission[] | null>;
  DeletePermission(
    libraryId: number,
    principalId: string
  ): Promise<void | null>;
  UpdateMetadata(
    libraryId: number,
    fields: IUpdateMetadataField[]
  ): Promise<ILibrary | null>;
  AddMetadataField(
    libraryId: number,
    field: IMetadataField
  ): Promise<ILibrary | null>;
}

export const LibraryServiceContext = createContext<ILibraryService | undefined>(
  undefined
);

const LibraryService: FC = ({ children }: any) => {
  const apiHttpService = useContext(ApiHttpServiceContext);
  const cacheService = useContext(CacheServiceContext);

  const libraryService: ILibraryService = {
    async GetAll() {
      return await apiHttpService!.Get<ILibrary[]>("libraries");
    },
    async Get(libraryId: number) {
      return await apiHttpService!.Get<ILibrary>(`libraries/${libraryId}`);
    },
    async Delete(libraryId: number) {
      return await apiHttpService!.Delete<void>(`libraries/${libraryId}`);
    },
    async Create(library: INewLibrary) {
      return await apiHttpService!.Post<ILibrary>(`libraries`, library);
    },
    async Update(libraryId: number, library: INewLibrary) {
      cacheService!.ClearCache(`libraries/${libraryId}`);
      return await apiHttpService!.Put<ILibrary>(
        `libraries/${libraryId}`,
        library
      );
    },
    async GetPermissions(libraryId: number) {
      return await apiHttpService!.Get<ILibraryPermission[]>(
        `libraries/${libraryId}/permissions`
      );
    },
    async CreatePermission(
      libraryId: number,
      principalId: string,
      type: PermissionType
    ) {
      return await apiHttpService!.Post<ILibraryPermission>(
        `libraries/${libraryId}/permissions`,
        {
          PrincipalId: principalId,
          PermissionType: type,
        }
      );
    },
    async UpdatePermission(
      libraryId: number,
      principalId: string,
      type: PermissionType
    ) {
      return await apiHttpService!.Put<ILibraryPermission>(
        `libraries/${libraryId}/permissions`,
        {
          PrincipalId: principalId,
          PermissionType: type,
        }
      );
    },
    async DeletePermission(libraryId: number, principalId: string) {
      await apiHttpService!.Delete<ILibraryPermission>(
        `libraries/${libraryId}/permissions/${principalId}`
      );
    },
    async UpdateMetadata(libraryId: number, fields: IUpdateMetadataField[]) {
      return await apiHttpService!.Put<ILibrary>(
        `libraries/${libraryId}/metadata`,
        {
          Fields: fields,
        }
      );
    },
    async AddMetadataField(libraryId: number, field: IMetadataField) {
      return await apiHttpService!.Post<ILibrary>(
        `libraries/${libraryId}/metadata`,
        field
      );
    },
    async CheckUserPermissions(principalId: string, libraryId: number) {
      return await apiHttpService!.Get<IUserPermission[]>(
        `libraries/${libraryId}/permissions/${principalId}`
      );
    },
  };

  return (
    <LibraryServiceContext.Provider value={libraryService}>
      {children}
    </LibraryServiceContext.Provider>
  );
};

export default LibraryService;
